import axios from "../../../apis/Api";
import history, { logout } from "../../../history";
import storage from "../../../apis/Storage";
import { GET_DAILY_COLLECTIONS_REPORT } from "../../../Types";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import fileSaver from "file-saver";
import * as XLSX from "xlsx";

export const getDailyCollections = (request) => async (dispatch) => {
  dispatch(showLoading());
  await axios
    .post(`api/Reports/GetDailyCollectionsReport`, request)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_DAILY_COLLECTIONS_REPORT,
        payload: data.response,
      });
      dispatch(hideLoading());
    })
    .catch(function (error) {
      dispatch(hideLoading());
      if (error.response) {
        if (error.response.status === 401) {
          logout();
          history.push("/login");
          history.go("/login");
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          );
        else {
          toast.error("Error while processing the request");
        }
      }
    });
};

export const exportReport = (requestData) => async (dispatch) => {
  dispatch(showLoading());
  await axios
    .post(`api/Reports/ExportDailyCollectionsReport`, requestData)
    .then(({ data }) => {
      const outputFilename = `Daily_Collections_Report_${Date.now()}`;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const exportToCSV = (csvData, jobNumber, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        if (ws) {
          var range = XLSX.utils.decode_range(ws["!ref"]);
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
            if (!ws[address]) continue;
            ws[address].v =
              ws[address].v == "distCenter"
                ? "Distribution Center"
                : ws[address].v == "username"
                ? "User Name"
                : ws[address].v == "deliveryMan"
                ? "Delivery Man"
                : ws[address].v == "tillNo"
                ? "VL Till No"
                : ws[address].v == "mfi"
                ? "Payment Type"
                : ws[address].v == "vizproInvoiceNo"
                ? "Invoice Number"
                : ws[address].v == "orderStatus"
                ? "Order Status"
                : ws[address].v == "orderDate"
                ? "Order Date"
                : ws[address].v == "orderAmount"
                ? "Order Amount"
                : ws[address].v == "transactionDate"
                ? "Date"
                : ws[address].v;
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        fileSaver.saveAs(fileData, fileName + fileExtension);
      };
      exportToCSV(data.response, "", outputFilename);
      dispatch(hideLoading());
    })

    .catch(function (error) {
      dispatch(hideLoading());
      if (error.response) {
        if (error.response.status === 401) {
          logout();
          history.push("/login");
          history.go("/login");
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          );
        else {
          toast.error("Error while processing the request");
        }
      }
    });
};
