import { GET_SCR_SUMMARY_REPORT } from "../../../../Types"

const SCRSummaryReportReducer = (state = [], action) => {
    switch (action.type) {
        case GET_SCR_SUMMARY_REPORT:
            return action.payload
        default:
            return state
    }
}
export default SCRSummaryReportReducer