import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Row,
  Col,
  Container,
  FormLabel,
  Button,
  Image,
} from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SCRCompleteDetailReportPrint from "./SCRCompleteDetailReportPrint";
import Moment from "react-moment";
import { VzPageContainer } from "../../../../layouts/VzPageContainer";
import { getScrCompleteDetailReport } from "./SCRCompleteDetailReportActions";
import SCRChequeImagePrint from "./SCRChequeImagePrint";
import SCRInvoiceImagePrint from "./SCRInvoiceImagePrint";
import SCRIBFTImagePrint from "./SCRIBFTImagePrint";
import SCRTranscriptImagePrint from "./SCRTranscriptImagePrint";

const SCRCompleteDetailReport = (props) => {
  let history = useHistory();

  let obj = {
    id: 0,
    date: new Date(),
  };
  const { data } =
    props.location.state != undefined ? props.location.state : obj;

  const componentRef = useRef();

  const subHeadingStyle = {
    fontWeight: 700,
  };

  const tableHeaderStyle = { color: "#99A0B5", backgroundColor: "#f4f4f4" };

  const firstColStyle = { width: "200px" };

  const secondColStyle = { width: "200px" };

  const thirdColStyle = { width: "150px" };

  const [showChequeViewImage, setShowChequeViewImage] = useState(false);
  const [showCreditViewImage, setShowCreditViewImage] = useState(false);
  const [showIBFTViewImage, setShowIBFTViewImage] = useState(false);
  const [showTransactionViewImage, setShowTransactionViewImage] =
    useState(false);

  const [rowData, setRowData] = useState({});

  const ViewChequeImage = (d) => {
    setRowData(d);
    setShowChequeViewImage(true);
  };

  const ViewCreditImage = (d) => {
    setRowData(d);
    setShowCreditViewImage(true);
  };

  const ViewIBFTImage = (d) => {
    setRowData(d);
    setShowIBFTViewImage(true);
  };

  const ViewTransactionImage = (d) => {
    setRowData(d);
    setShowTransactionViewImage(true);
  };

  useEffect(() => {
    getViewDetailData(data.id, data.date);
  }, []);

  const getViewDetailData = (id, date) => {
    let requestData = {
      dlmUserId: id,
      date: date,
    };

    props.getScrCompleteDetailReport(requestData);
  };

  const getSummaryReport = (d) => {
    let params = {
      date: d.date,
      id: d.id,
    };

    history.push("/SCRSummaryReport", { params });

    <Link
      to={{
        pathname: "/SCRSummaryReport",
        state: params,
      }}
    ></Link>;
  };

  return (
    <>
      <VzPageContainer
        title="SCR Complete Detail Report"
        ref={componentRef}
        style={{ padding: "0px 20px", margin: "30px 0px" }}
      >
        <div>
          <Row>
            <Col>
              <FormLabel>Distributor:</FormLabel>
            </Col>
            <Col>{props.detail?.distributorName}</Col>
            <Col>
              <FormLabel>Distribution Center:</FormLabel>
            </Col>
            <Col>{props.detail?.distCenterName}</Col>
            <Col>
              <FormLabel>Date:</FormLabel>
            </Col>
            <Col>
              <Moment format="DD/MM/YYYY">
                {props.detail?.date ? props.dataModel?.date : new Date()}
              </Moment>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>DLM User ID:</FormLabel>
            </Col>
            <Col>{props.detail?.dlmUserId}</Col>
            <Col>
              <FormLabel>DLM Name:</FormLabel>
            </Col>
            <Col>{props.detail?.dlmName}</Col>
            <Col>
              <FormLabel>DLM TILL ID:</FormLabel>
            </Col>
            <Col>{props.detail?.dlmTillId}</Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>Total Invoices in Vizlink:</FormLabel>
            </Col>
            <Col>{props.detail?.totalInvoices}</Col>
            <Col>
              <FormLabel>Total Invoices closed in Vizlink:</FormLabel>
            </Col>
            <Col>{props.detail?.totalInvoicesClosed}</Col>
            <Col>
              <FormLabel>Total Invoices Amount:</FormLabel>
            </Col>
            <Col>{props.detail?.totalInvoicesAmount}</Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>Total Collected Amount:</FormLabel>
            </Col>
            <Col>{props.detail?.totalCollectedAmount}</Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>1-Total Cheque Collected</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Cheque Amount</th>
                    <th className="border-0">Cheque #</th>
                    <th className="border-0">Cheque Date</th>
                    <th className="border-0">Payer Bank</th>
                    <th className="border-0">Cheque Image</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.totalChequeCollectedList &&
                  props.detail?.totalChequeCollectedList.length > 0 ? (
                    props.detail?.totalChequeCollectedList.map((cheque, j) => (
                      <tr key={cheque}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.chequeNo}</td>
                        <td>
                          <Moment format="DD/MM/YYYY">
                            {cheque.chequeDate ? cheque.chequeDate : new Date()}
                          </Moment>
                        </td>
                        <td>{cheque.payerBankId}</td>
                        <td>
                          {cheque.filePath && cheque.filePath.length > 0 ? (
                            <Link
                              onClick={() => ViewChequeImage(cheque)}
                              style={{ cursor: "pointer", width: "106px" }}
                              to="#"
                            >
                              View Image
                            </Link>
                          ) : (
                            "Not Available"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>2-Total Credit Disbursed</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Due Date</th>
                    <th className="border-0">Amount</th>
                    <th className="border-0">Cheque Image</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.totalCreditDisbursedList &&
                  props.detail?.totalCreditDisbursedList.length > 0 ? (
                    props.detail?.totalCreditDisbursedList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>
                          <Moment format="DD/MM/YYYY">
                            {cheque.dueDate ? cheque.dueDate : new Date()}
                          </Moment>
                        </td>
                        <td>{cheque.amount}</td>
                        <td>
                          {cheque.filePath && cheque.filePath.length > 0 ? (
                            <Link
                              onClick={() => ViewCreditImage(cheque)}
                              style={{ cursor: "pointer", width: "106px" }}
                              to="#"
                            >
                              View Image
                            </Link>
                          ) : (
                            "Not Available"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>3-IBFT</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Dist Bank</th>
                    <th className="border-0">Amount</th>
                    <th className="border-0">Payer Name</th>
                    <th className="border-0">TID #</th>
                    <th className="border-0">Payer Bank</th>
                    <th className="border-0">IBFT Image</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.ibftList &&
                  props.detail?.ibftList.length > 0 ? (
                    props.detail?.ibftList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.distBankId}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.payerName}</td>
                        <td>{cheque.tid}</td>
                        <td>{cheque.payerBankId}</td>
                        <td>
                          {cheque.filePath && cheque.filePath.length > 0 ? (
                            <Link
                              onClick={() => ViewIBFTImage(cheque)}
                              style={{ cursor: "pointer", width: "106px" }}
                              to="#"
                            >
                              View Image
                            </Link>
                          ) : (
                            "Not Available"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>4-Collections Via Vizlink</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">MFI</th>
                    <th className="border-0">Total Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.collectionViaVizlink &&
                  props.detail?.collectionViaVizlink.length > 0 ? (
                    props.detail?.collectionViaVizlink.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.mfiType}</td>
                        <td>{cheque.amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
            <Col>
              <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <Col xs={12} md={12}>
                  <h6 style={subHeadingStyle}>a- Vizlink Collection Summary</h6>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  style={{ paddingLeft: "60px", paddingRight: "60px" }}
                >
                  <table className="table table-sm table-bordered">
                    <thead style={tableHeaderStyle}>
                      <tr>
                        <th className="border-0" style={firstColStyle}>
                          Collection
                        </th>
                        <th className="border-0" style={secondColStyle}>
                          Vizlink Till #
                        </th>
                        <th className="border-0" style={thirdColStyle}>
                          Amount Collected
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.detail?.vizlinkCollectionSummary &&
                      props.detail?.vizlinkCollectionSummary.length > 0 ? (
                        props.detail?.vizlinkCollectionSummary.map(
                          (item, j) => (
                            <tr key={item.collection + j}>
                              <td>{item.collection}</td>
                              <td>{item.vizlinkTillNo}</td>
                              <td>{item.amountCollected}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={12} style={{ textAlign: "center" }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>5-Outside Vizlink Payment</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">MFI</th>
                    <th className="border-0">Amount</th>
                    <th className="border-0">Payer #</th>
                    <th className="border-0">TID #</th>
                    <th className="border-0">TILL #</th>
                    <th className="border-0">Reason</th>
                    <th className="border-0">Transaction Image</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.outsideVizlinkList &&
                  props.detail?.outsideVizlinkList.length > 0 ? (
                    props.detail?.outsideVizlinkList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.mfiType}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.payerName}</td>
                        <td>{cheque.tid}</td>
                        <td>{cheque.tillId}</td>
                        <td>{cheque.reason}</td>
                        <td>
                          {cheque.filePath && cheque.filePath.length > 0 ? (
                            <Link
                              onClick={() => ViewTransactionImage(cheque)}
                              style={{ cursor: "pointer", width: "106px" }}
                              to="#"
                            >
                              View Image
                            </Link>
                          ) : (
                            "Not Available"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>6-RTG</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">RTG</th>
                    <th className="border-0">Reason For RTG</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.rtgList && props.detail?.rtgList.length > 0 ? (
                    props.detail?.rtgList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>7-Market Return</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Market Return Amount</th>
                    <th className="border-0">Reason For RTG</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.marketReturnList &&
                  props.detail?.marketReturnList.length > 0 ? (
                    props.detail?.marketReturnList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>8-Additional Sale</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Original Invoice Amount</th>
                    <th className="border-0">Additional Sale</th>
                    <th className="border-0">Revised Invoice Amount</th>
                    <th className="border-0">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.additionalSaleList &&
                  props.detail?.additionalSaleList.length > 0 ? (
                    props.detail?.additionalSaleList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.originalInvoiceAmount}</td>
                        <td>{cheque.additionalSales}</td>
                        <td>{cheque.revisedInoviceAmount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>9-Cash Collection</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Cash Collection</th>
                    <th className="border-0">Reason For Cash Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {props.detail?.cashPaymentList &&
                  props.detail?.cashPaymentList.length > 0 ? (
                    props.detail?.cashPaymentList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber + j}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col sx={6} md={6}>
              <Button
                variant="primary"
                onClick={() => getSummaryReport(data)}
                style={{
                  right: "110px",
                  bottom: "25px",
                  marginRight: "10px",
                }}
              >
                Summary Report
              </Button>
            </Col>
            <Col sx={6} md={6}>
              <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="primary"
                      style={{
                        left: "20px",
                        bottom: "20px",
                        float: "right",
                        width: "120px",
                      }}
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                  <SCRCompleteDetailReportPrint
                    dataModel={props.detail}
                    innerRef={(el) => (componentRef.current = el)}
                  />
                </div>
              </div>
              <Button
                variant="default"
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  right: "110px",
                  bottom: "25px",
                  float: "right",
                  width: "120px",
                  marginRight: "10px",
                }}
              >
                Close
              </Button>
            </Col>
          </Row>
        </div>
      </VzPageContainer>

      {/* for showing cheque image */}

      <Modal
        closeModal
        show={showChequeViewImage}
        onHide={() => setShowChequeViewImage(false)}
        size="lg"
      >
        <Modal.Header
          id="customized-dialog-title"
          style={{ alignSelf: "center" }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Cheque Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>Dist Shop Code/Viz ID:</Col>
              <Col>
                {rowData.distShopCode} / {rowData.vizShopCode}
              </Col>
              <Col>Shop Name:</Col>
              <Col>{rowData.shopName}</Col>
            </Row>
            <Row>
              <Col>Invoice #:</Col>
              <Col>{rowData.invoiceNumber}</Col>
              <Col>Bank Payer:</Col>
              <Col>{rowData.payerBankId}</Col>
            </Row>
            <Row>
              <Col>Cheque Amount:</Col>
              <Col>{rowData.amount}</Col>
              <Col>Cheque #:</Col>
              <Col>{rowData.chequeNo}</Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Row>
              <Col>
                <Image
                  style={{ height: "100%", width: "100%" }}
                  src={rowData.filePath}
                ></Image>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            {/* button to trigger printing of target component */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="primary"
                  style={{
                    left: "20px",
                    bottom: "20px",
                    float: "right",
                    width: "120px",
                  }}
                >
                  Download
                </Button>
              )}
              content={() => componentRef.current}
            />

            {/* component to be printed */}
            <div style={{ display: "none" }}>
              <SCRChequeImagePrint
                dataModel={rowData}
                innerRef={(el) => (componentRef.current = el)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* for showing credit disbursemet image */}

      <Modal
        closeModal
        show={showCreditViewImage}
        onHide={() => setShowCreditViewImage(false)}
        size="lg"
      >
        <Modal.Header
          id="customized-dialog-title"
          style={{ alignSelf: "center" }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Invoice Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>Dist Shop Code/Viz ID:</Col>
              <Col>
                {rowData.distShopCode} / {rowData.vizShopCode}
              </Col>
              <Col>Shop Name:</Col>
              <Col>{rowData.shopName}</Col>
            </Row>
            <Row>
              <Col>Invoice #:</Col>
              <Col>{rowData.invoiceNumber}</Col>
              <Col>Due Date:</Col>
              <Col>
                <Moment format="DD/MM/YYYY">
                  {rowData?.dueDate ? rowData?.dueDate : new Date()}
                </Moment>
              </Col>
            </Row>
            <Row>
              <Col>Amount:</Col>
              <Col>Rs.{rowData.amount}</Col>
              <Col></Col>
              <Col></Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Row>
              <Col>
                <Image
                  style={{ height: "100%", width: "100%" }}
                  src={rowData.filePath}
                ></Image>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            {/* button to trigger printing of target component */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="primary"
                  style={{
                    left: "20px",
                    bottom: "20px",
                    float: "right",
                    width: "120px",
                  }}
                >
                  Download
                </Button>
              )}
              content={() => componentRef.current}
            />

            {/* component to be printed */}
            <div style={{ display: "none" }}>
              <SCRInvoiceImagePrint
                dataModel={rowData}
                innerRef={(el) => (componentRef.current = el)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* for showing IBFT image */}
      <Modal
        closeModal
        show={showIBFTViewImage}
        onHide={() => setShowIBFTViewImage(false)}
        size="lg"
      >
        <Modal.Header
          id="customized-dialog-title"
          style={{ alignSelf: "center" }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Cheque Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>Dist Shop Code/Viz ID:</Col>
              <Col>
                {rowData.distShopCode} / {rowData.vizShopCode}
              </Col>
              <Col>Shop Name:</Col>
              <Col>{rowData.shopName}</Col>
            </Row>
            <Row>
              <Col>Invoice #:</Col>
              <Col>{rowData.invoiceNumber}</Col>
              <Col>Dist Bank:</Col>
              <Col>{rowData.distBankId}</Col>
            </Row>
            <Row>
              <Col>Amount:</Col>
              <Col>{rowData.amount}</Col>
              <Col>Payer Name:</Col>
              <Col>{rowData.payerName}</Col>
            </Row>
            <Row>
              <Col>Payer Bank:</Col>
              <Col>{rowData.payerBankId}</Col>
              <Col>TID #:</Col>
              <Col>{rowData.tid}</Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Row>
              <Col>
                <Image
                  style={{ height: "100%", width: "100%" }}
                  src={rowData.filePath}
                ></Image>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            {/* button to trigger printing of target component */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="primary"
                  style={{
                    left: "20px",
                    bottom: "20px",
                    float: "right",
                    width: "120px",
                  }}
                >
                  Download
                </Button>
              )}
              content={() => componentRef.current}
            />

            {/* component to be printed */}
            <div style={{ display: "none" }}>
              <SCRIBFTImagePrint
                dataModel={rowData}
                innerRef={(el) => (componentRef.current = el)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* for showing outside vizlink payment/transaction image */}
      <Modal
        closeModal
        show={showTransactionViewImage}
        onHide={() => setShowTransactionViewImage(false)}
        size="lg"
      >
        <Modal.Header
          id="customized-dialog-title"
          style={{ alignSelf: "center" }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Transcript Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>Dist Shop Code/Viz ID:</Col>
              <Col>
                {rowData.distShopCode} / {rowData.vizShopCode}
              </Col>
              <Col>Shop Name:</Col>
              <Col>{rowData.shopName}</Col>
            </Row>
            <Row>
              <Col>Invoice #:</Col>
              <Col>{rowData.invoiceNumber}</Col>
              <Col>Payer Name:</Col>
              <Col>{rowData.payerName}</Col>
            </Row>
            <Row>
              <Col>Amount:</Col>
              <Col>{rowData.amount}</Col>
              <Col>TID #:</Col>
              <Col>{rowData.tid}</Col>
            </Row>
            <Row>
              <Col>Payer Bank:</Col>
              <Col>{rowData.mfiType}</Col>
              <Col>Till #.</Col>
              <Col>{rowData.tillId}</Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Row>
              <Col>
                <Image
                  style={{ height: "100%", width: "100%" }}
                  src={rowData.filePath}
                ></Image>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "20px" }}>
            {/* button to trigger printing of target component */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="primary"
                  style={{
                    left: "20px",
                    bottom: "20px",
                    float: "right",
                    width: "120px",
                  }}
                >
                  Download
                </Button>
              )}
              content={() => componentRef.current}
            />

            {/* component to be printed */}
            <div style={{ display: "none" }}>
              <SCRTranscriptImagePrint
                dataModel={rowData}
                innerRef={(el) => (componentRef.current = el)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    detail: state.SCRCompleteDetailReport,
  };
};
export default connect(mapStateToProps, {
  getScrCompleteDetailReport,
})(SCRCompleteDetailReport);
