import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Image } from "react-bootstrap";

const SCRChequeImagePrint = ({ dataModel, innerRef }) => {
  return (
    <>
      <Container
        ref={innerRef}
        style={{ paddingTop: "50px", fontSize: "12px" }}
      >
        <div>
          <Row>
            <Col>
              <h5 style={{ textAlign: "center" }}>Cheque Image</h5>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>Dist Shop Code/Viz ID:</Col>
            <Col>
              {dataModel?.distShopCode} / {dataModel?.vizShopCode}
            </Col>
            <Col>Shop Name:</Col>
            <Col>{dataModel?.shopName}</Col>
          </Row>
          <Row>
            <Col>Invoice #:</Col>
            <Col>{dataModel?.invoiceNumber}</Col>
            <Col>Bank Payer:</Col>
            <Col>{dataModel?.payerBankId}</Col>
          </Row>
          <Row>
            <Col>Cheque Amount:</Col>
            <Col>{dataModel?.amount}</Col>
            <Col>Cheque #:</Col>
            <Col>{dataModel?.chequeNo}</Col>
          </Row>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col>
              <Image
                style={{ height: "100%", width: "100%" }}
                src={dataModel?.filePath}
              ></Image>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default SCRChequeImagePrint;
