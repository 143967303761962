import { connect } from "react-redux";
import { useState, useEffect } from "react";

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
  Image,
} from "react-bootstrap";
import CustomDataTable from "../../../../layouts/DataTables/CustomDataTable";
import { Input } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import {
  addUserRolePermissions,
  deleteUserRolePermissions,
  getUserRolePermissions,
} from "./UserRolePermissionActions";
import ReactSelect from "react-select";
import { act } from "@testing-library/react";

const UserRolePermissions = (props) => {
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const changeRight = (pageId, ActionId) => {
    let data = [...selectedRolePermissions];
    data.forEach((page) => {
      page.childPages.forEach((child) => {
        if (child.pageId == pageId) {
          let currentAction = child.actions.find((x) => x.id == ActionId);
          let currentChecked = currentAction.ischecked;
          if (currentAction.isSystemAction) {
            child.actions.forEach((action) => {
              if (currentChecked == true) {
                action.ischecked = !currentChecked;
              } else {
                if (action.id == ActionId) {
                  action.ischecked = !action.ischecked;
                }
              }
            });
          } else {
            child.actions.forEach((action) => {
              if (action.id == ActionId) {
                action.ischecked = !action.ischecked;
              }
            });
            if (
              child.actions.filter((x) => !x.isSystemAction && x.ischecked)
                .length > 0
            ) {
              let index = child.actions.findIndex((x) => x.isSystemAction);
              if (index >= 0) child.actions[index].ischecked = true;
            }
          }
        }
      });
    });

    setSelectedRolePermissions(data);
  };

  const selectAllChild = (pageId) => {
    let data = [...selectedRolePermissions];
    data.forEach((page) => {
      page.childPages.forEach((child) => {
        if (child.pageId == pageId) {
          let checkedUnCheckedValues = child?.actions[0].ischecked;
          child?.actions?.forEach((action) => {
            // if (checkedUnCheckedValues.length == child?.actions )
            action.ischecked = !checkedUnCheckedValues;
          });
        }
      });
    });

    setSelectedRolePermissions(data);
  };

  const selectAllPermissions = () => {
    let data = [...selectedRolePermissions];
    data.forEach((page) => {
      page.childPages.forEach((child) => {
        child?.actions?.forEach((action) => {
          action.ischecked = !isSelectAll;
        });
      });
    });

    setSelectedRolePermissions(data);
    // setIsSelectAll(!isSelectAll)
  };

  const checkIfAllSelected = () => {
    let checkedCount = 0;
    let actionsCount = 0;
    selectedRolePermissions?.forEach((page) => {
      page.childPages?.forEach((child) => {
        child.actions?.forEach((action) => {
          actionsCount++;
          if (action.ischecked == true) {
            checkedCount++;
          }
        });
      });
    });
    if (checkedCount == actionsCount) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };

  const Save = () => {
    if (selectedUserRole?.value != null) {
      let Permissions = selectedRolePermissions.map((x) => {
        return {
          ...x,
          UserRoleId: selectedUserRole?.id,
          RoleName: selectedUserRole?.value,
        };
      });
      props.addUserRolePermissions(Permissions);
    } else {
      toast.error("Select User Role");
    }
  };

  useEffect(() => {
    if (selectedUserRole != null) {
      props.getUserRolePermissions(selectedUserRole.id);
    }
  }, [selectedUserRole]);

  useEffect(() => {
    setSelectedRolePermissions(props.UserRolePermission);
  }, [props.UserRolePermission]);

  useEffect(() => {
    checkIfAllSelected();
  }, [selectedRolePermissions]);

  return (
    <div>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={12} style={{ marginTop: "" }}>
          <Row
            style={{
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Col md={4}>
              <div className="form-group">
                <ReactSelect
                  id="userRole"
                  name="userRole"
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select User Role"
                  isMulti={false}
                  value={selectedUserRole}
                  isClearable={true}
                  onChange={(res) => setSelectedUserRole(res)}
                  options={
                    props.UserRoles.filter((x) => x.moduleTypeId == 6) ?? []
                  }
                ></ReactSelect>
              </div>
            </Col>
            <Col md={1}>
              {" "}
              <Button
                disabled={selectedUserRole == null}
                className="btn btn-primary"
                onClick={Save}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={2} style={{ marginLeft: "40px" }}>
          <div className=" mb-2 mt-1">
            <input
              disabled={selectedUserRole == null ? true : false}
              value={isSelectAll}
              checked={isSelectAll}
              onChange={(event) => {
                selectAllPermissions();
              }}
              type="checkbox"
              style={{
                transform: "scale(1.3)",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            <span
              onClick={() => {
                if (selectedUserRole == null) return;
                // changeRight(childPage.pageId, action.id)
                selectAllPermissions();
              }}
              style={{ cursor: "pointer" }}
            >
              Select All
            </span>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {selectedRolePermissions?.map((page) => {
          return (
            <>
              <Row>
                <Col xs={12} md={6}>
                  <h5>
                    <b>{page.pageName}</b>
                  </h5>
                </Col>
              </Row>
              {page?.childPages?.map((childPage) => {
                return (
                  <>
                    {childPage.actions && childPage.actions?.length > 0 ? (
                      <Row>
                        <Col xs={12} style={{ marginLeft: "30px" }}>
                          <Row>
                            <Col xs={12} md={6}>
                              <h5
                                onClick={() => {
                                  if (selectedUserRole == null) return;
                                  selectAllChild(childPage.pageId);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <>{childPage.pageName}</>
                              </h5>
                            </Col>
                            <Col xs={12} md={6}>
                              {/* <Button
                              disabled={selectedUserRole == null}
                              className='btn btn-primary'
                              style={{
                                maxHeight: '30px',
                                maxWidth: '90px',
                              }}
                              onClick={() => selectAllChild(childPage.pageId)}
                            >
                              Select All
                            </Button> */}
                              {/* <Image
                              src={
                                'https://www.clipartmax.com/png/small/246-2465641_png-file-multiple-select-icon.png'
                              }
                              alt='Logo'
                              className='brand-image'
                              height={20}
                              width={20}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (selectedUserRole == null) return
                                selectAllChild(childPage.pageId)
                              }}
                            ></Image> */}
                            </Col>
                          </Row>
                          <Row>
                            {/* {childPage.actions?.map((action) => {
                              return (
                                <>
                                  <Col xs={12} md={2}>
                                    <div className=' mb-2 mt-1'>
                                      <input
                                        disabled={
                                          selectedUserRole == null
                                            ? true
                                            : false
                                        }
                                        value={action.ischecked}
                                        checked={action.ischecked}
                                        onChange={(event) => {
                                          changeRight(
                                            childPage.pageId,
                                            action.id
                                          )
                                        }}
                                        type='checkbox'
                                        style={{
                                          transform: 'scale(1.3)',
                                          marginRight: '10px',
                                          cursor: 'pointer',
                                        }}
                                      />
                                      <span
                                        onClick={() => {
                                          if (selectedUserRole == null) return
                                          changeRight(
                                            childPage.pageId,
                                            action.id
                                          )
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {action.actionName}
                                      </span>
                                    </div>
                                  </Col>
                                </>
                              )
                            })} */}

                            {childPage.actions?.map((action) => {
                              return (
                                <>
                                  <Col xs={12} md={2}>
                                    <div className=" mb-2 mt-1">
                                      <input
                                        disabled={
                                          selectedUserRole == null
                                            ? true
                                            : false
                                        }
                                        value={action.ischecked}
                                        checked={action.ischecked}
                                        onChange={(event) => {
                                          changeRight(
                                            childPage.pageId,
                                            action.id
                                          );
                                        }}
                                        type="checkbox"
                                        style={{
                                          transform: "scale(1.3)",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <span
                                        onClick={() => {
                                          if (selectedUserRole == null) return;
                                          changeRight(
                                            childPage.pageId,
                                            action.id
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {action.actionName}
                                      </span>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </>
          );
        })}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    UserRoles: state.UserRoles,
    Pages: state.Pages,
    UserRolePermission: state.UserRolePermission,
    PageActions: state.PageActions,
  };
};

export default connect(mapStateToProps, {
  addUserRolePermissions,
  deleteUserRolePermissions,
  getUserRolePermissions,
})(UserRolePermissions);
