import React, { useEffect, useState } from "react";
import { VzPageContainer } from "../../../layouts/VzPageContainer";
import { connect } from "react-redux";
import { Modal, Col, Row, Button } from "react-bootstrap";
import ServerSideDataTable from "../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable";
import CustomDataTable from "../../../layouts/DataTables/CustomDataTable";
import axios from "../../../apis/Api";
import { getDropdownList } from "../../Shared/dropdownListActions";
import { confirmAlert } from "react-confirm-alert";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import Moment from "react-moment";
import { Input } from "reactstrap";
import { useHistory } from "react-router-dom";

export const showLoader = () => async (dispatch) => {
  dispatch(showLoading());
};

export const hideLoader = () => async (dispatch) => {
  dispatch(hideLoading());
};

const InvoiceAllocationListingDetail = (props) => {
  const history = useHistory();

  let obj = [];
  const { requestPrevious } =
    props.location.state != undefined ? props.location.state : obj;

  const [filter, setfilter] = useState({
    dist: requestPrevious.distId,
    distCenter: requestPrevious.distCenterId,
    deliveryMan: requestPrevious.deliveryMan,
    pageNo: 0,
    searchText: "",
    obid: "",
    isExport: false,
  });

  const [invoiceList, setInvoiceList] = useState([]);
  const [allDeliveryManList, setAllDeliveryManList] = useState([]);
  const [deliveryManFilterList, setDeliveryManFilterList] = useState([]);

  const [mainDLM, setMainDLM] = useState("");
  const [mainDLMTillNo, setMainDLMTillNo] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  function formRequestObject() {
    let requestData = {
      distId: filter.dist,
      distCenterId: filter.distCenter,
      deliveryMan: filter.deliveryMan,
      obid: filter.obid?.value,
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
      isExport: filter.isExport,
    };

    return requestData;
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0);
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize };
  };

  const getData = (page) => {
    setCurrentPageNo(page);
    var request = { ...formRequestObject(), pageNo: page };
    fetchReportData(request);
  };

  const searchChanged = (text) => {
    setCurrentPageNo(0);
    setfilter({ ...filter, searchText: text, currentPageNo: 0 });
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    };
    fetchReportData(request);
  };

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    getInvoiceList(finalRequest);
  }

  const getInvoiceList = (request) => {
    props.showLoader();
    axios
      .post(`api/ShopOrder/GetDeliveryManWiseInvoiceList`, request)
      .then(({ data }) => {
        debugger;
        setInvoiceList(data?.response.data);
        setTotalRecords(data?.response.totalRecords);
        props.hideLoader();
      })
      .catch(function (error) {
        props.hideLoader();
      });
  };

  const getAllDeliveryUserList = () => {
    axios
      .get(`api/User/GetAllDeliveryUsers`)
      .then(({ data }) => {
        setAllDeliveryManList(data?.response);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    fetchReportData();
    getAllDeliveryUserList();
  }, []);

  const deliveryDropDown = (centerId) => {
    let dUsersList = [...allDeliveryManList];
    let centerUsers = dUsersList
      .filter((x) => x.distCenterId == centerId)
      .map(function (item) {
        return {
          value: item.id,
          label: item?.fullname,
        };
      });

    return centerUsers;
  };

  const asignDeliveryManToInvoice = (invoice, delMan) => {
    debugger;
    let dUsersList = [...allDeliveryManList];
    let invoices = [...invoiceList];
    let index = invoices.findIndex((x) => x.invoiceNo == invoice);
    invoices[index].reAllocatedDeliveryMan = delMan.value;

    let dlm = dUsersList.find((x) => x.id == delMan.value);

    invoices[index].reAllocatedDeliveryManId = dlm.id;
    invoices[index].reAllocatedTillNo = dlm.qrCode;
    setInvoiceList(invoices);
  };

  const selectedMainDLM = (delMan) => {
    let dUsersList = [...allDeliveryManList];

    let dlm = dUsersList.find((x) => x.id == delMan.value);

    setMainDLM(delMan);

    setMainDLMTillNo(dlm.qrCode);
  };

  const selectedDeliveryUser = (userId) => {
    debugger;
    if (userId == "" || userId == null) return;
    let users = [...allDeliveryManList];
    let selectedUser = users.find((x) => x.id == userId);
    return {
      label: selectedUser?.fullname,
      value: selectedUser.id,
    };
  };

  const assignHandler = () => {
    let invoices = [...invoiceList];

    invoices = invoices.filter((x) => x.isSelected == true);

    if (invoices.length > 0) {
      invoices = invoiceList.map((invoice) => {
        if (invoice.isSelected) {
          invoice.reAllocatedDeliveryMan = mainDLM.value;
          invoice.reAllocatedTillNo = mainDLMTillNo;
        }
        return invoice;
      });

      setInvoiceList(invoices);
    }
  };

  const reset = () => {
    let invoices = [...invoiceList];

    invoices = invoices.filter((x) => x.isSelected == true);

    if (invoices.length > 0) {
      invoices = invoiceList.map((invoice) => {
        invoice.reAllocatedDeliveryMan = "";
        invoice.reAllocatedTillNo = "";
        invoice.isSelected = false;
        return invoice;
      });

      setInvoiceList(invoices);
      setIsSelectAll(false);
      setMainDLM("");
      setMainDLMTillNo("");
    }
  };

  const selectAll = () => {
    setIsSelectAll(!isSelectAll);

    let invoices = invoiceList.map((element) => {
      element.isSelected = !isSelectAll;
      return element;
    });

    setInvoiceList(invoices);
  };

  const columns = [
    {
      Header: (
        <>
          <div className="ms-2">
            <input
              checked={isSelectAll}
              value={isSelectAll}
              type="checkbox"
              onChange={selectAll}
              style={{ transform: "scale(1.3)" }}
            />
          </div>
        </>
      ),
      accessor: "isSelected",
      Cell: (props) => (
        <>
          <div className="ms-2  ">
            <input
              disabled={props.row.original.status == "Paid"}
              checked={props.row.original.isSelected}
              value={props.row.original.isSelected}
              type="checkbox"
              onChange={() => selectInvoice(props.row.original)}
              style={{ transform: "scale(1.3)" }}
            />
          </div>
        </>
      ),
    },

    {
      Header: "Invoice No.",
      accessor: "invoiceNo",
    },

    {
      Header: "Invoice Amount",
      accessor: "amount",
    },

    {
      Header: "Allocated DLM",
      accessor: "deliveryMan",
    },

    {
      Header: "Deliveryman Name",
      accessor: "reAllocatedDeliveryMan",
      Cell: (props) => (
        <>
          {console.log(props.row)}
          <ReactSelect
            isDisabled={props.row.original.status == "Paid"}
            id="deliveryMan"
            name="deliveryMan"
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select Delivery Man"
            isMulti={false}
            value={selectedDeliveryUser(
              props.row.original.reAllocatedDeliveryManId
            )}
            isSearchable={true}
            onChange={(option) =>
              asignDeliveryManToInvoice(props.row.original.invoiceNo, option)
            }
            options={deliveryDropDown(props.row.original.distCenterId)}
            menuPlacement="auto"
          ></ReactSelect>
        </>
      ),
    },

    {
      Header: "Deliveryman Till ID",
      accessor: "reAllocatedTillNo",
    },
  ];

  const selectInvoice = (record) => {
    let invoices = [...invoiceList];
    let index = invoices.findIndex((x) => x.invoiceNo == record.invoiceNo);
    invoices[index].isSelected = !invoices[index].isSelected;
    setInvoiceList(invoices);

    if (
      invoices.length == invoiceList.filter((x) => x.isSelected == true).length
    ) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };

  const finalizeHandler = () => {
    showAlert(true);
  };
  const resetHandler = () => {
    showAlert(false);
  };

  const getAlertText = (isFinalize) => {
    let text =
      "Are you sure you want to " +
      (isFinalize ? "allocate selected invoices?" : "reset all selection?");

    return text;
  };

  const showAlert = (isFinalize) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Modal.Dialog>
              <Modal.Header id="customized-dialog-title">
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="btn-close"
                  aria-label="No"
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>{getAlertText(isFinalize)}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    isFinalize ? saveData() : reset();
                    onClose();
                  }}
                  variant="outline-primary"
                >
                  Yes, Do it!
                </Button>
                <Button
                  variant="primary"
                  onClick={onClose}
                  className="btn btn-primary"
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        );
      },
    });
  };

  const saveInvoiceAssignment = () => {
    let list = [...invoiceList.filter((x) => x.isSelected)];

    if (list.length == 0) {
      toast.error("Select invoice(s)");
      return;
    }

    finalizeHandler();
  };

  const saveData = () => {
    let list = [...invoiceList.filter((x) => x.isSelected)];

    let req = {
      invoices: list,
    };

    axios
      .post(`api/ShopOrder/ReAllocateInvoicesByDeliveryManIdFromWeb`, req)
      .then(({ data }) => {
        toast.success("Delivery man assigned successfully");
        history.goBack();
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (filter.distCenter) {
      var filteredDM = allDeliveryManList.filter(
        (x) => x.distCenterId == filter.distCenter.value.toLowerCase()
      );
      setDeliveryManFilterList(
        filteredDM.map(function (item) {
          return {
            value: item.id,
            label: item.fullname,
          };
        })
      );
    }
  }, [filter.distCenter]);

  return (
    <div>
      <VzPageContainer title="Invoice Allocation Listing">
        <Row>
          <Col md={{ span: 3 }} style={{ float: "left" }}>
            <h4>
              <b>Invoice Allocation Listing</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <h6 style={{ float: "right" }}>
              Date: <Moment format="DD-MMM-YYYY">{Date()}</Moment>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={7} md={7}></Col>
          <Col xs={2} md={2} style={{ maxWidth: "16%", marginLeft: "16px" }}>
            <div className="form-group">
              <ReactSelect
                id="mainDLM"
                name="mainDLM"
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select Deliveryman Name"
                isMulti={false}
                value={mainDLM}
                isClearable={true}
                onChange={(option) => {
                  selectedMainDLM(option);
                }}
                options={allDeliveryManList.map(function (item) {
                  return {
                    value: item.id,
                    label: item?.fullname,
                  };
                })}
              ></ReactSelect>
            </div>
          </Col>
          <Col xs={2} md={2} style={{ maxWidth: "16%" }}>
            <div className="form-group">
              <Input
                id="mainDLMTillNo"
                value={mainDLMTillNo}
                type="text"
                placeholder="Deliveryman Till ID"
              />
            </div>
          </Col>
          <Col xs={1} md={1} style={{ alignContent: "center" }}>
            <div className="form-group">
              <Button
                className="form-btn"
                variant="primary"
                onClick={assignHandler}
                style={{ fontSize: 15, float: "left", width: "100%" }}
              >
                Assign
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <ServerSideDataTable
              data={invoiceList ?? []}
              columns={columns}
              getTrProps={(event, data) => {}}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
              searchLabel="Search Invoice :"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={9} md={9}></Col>
          <Col xs={2} md={2} style={{ marginTop: "30px" }}>
            <div className="form-group">
              <Button
                className="form-btn"
                variant="outline-primary"
                onClick={resetHandler}
                style={{ fontSize: 15, float: "right" }}
              >
                Reset
              </Button>
            </div>
          </Col>
          <Col xs={1} md={1} style={{ marginTop: "30px" }}>
            <div className="form-group">
              <Button
                className="form-btn"
                variant="primary"
                onClick={saveInvoiceAssignment}
                style={{ fontSize: 15, float: "left" }}
              >
                Finalize
              </Button>
            </div>
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  };
};
export default connect(mapStateToProps, {
  getDropdownList,
  showLoader,
  hideLoader,
})(InvoiceAllocationListingDetail);
