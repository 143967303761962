import { useRef, useEffect, useState } from "react";
import { VzPageContainer } from "../../../layouts/VzPageContainer";
import { connect } from "react-redux";
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from "react-bootstrap";
import ServerSideDataTable from "../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable";
import axios from "../../../apis/Api";
import { getDropdownList } from "../../Shared/dropdownListActions";
import FormikControl from "../../../layouts/Input/FormikControl";
import { Formik, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import storage from "../../../apis/Storage";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../utilities/FUNCTIONS";
import Moment from "react-moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
export const showLoader = () => async (dispatch) => {
  dispatch(showLoading());
};

export const hideLoader = () => async (dispatch) => {
  dispatch(hideLoading());
};

const InvoiceReopeningListingDetail = (props) => {
  const history = useHistory();
  let obj = [];
  const { requestPrevious } =
    props.location.state != undefined ? props.location.state : obj;

  const [filter, setfilter] = useState({
    distCenter: requestPrevious?.distCenter,
    deliveryManId: requestPrevious?.deliveryManId,
    vizshopCode: "",
    invoiceNo: "",
    invoiceStatus: null,
    pageNo: 0,
    fromDate: requestPrevious?.fromDate
      ? new Date(requestPrevious.fromDate)
      : new Date(),
    toDate: requestPrevious?.toDate
      ? new Date(requestPrevious.toDate)
      : new Date(),
    searchText: "",
  });

  const userRights = JSON.parse(storage.get("_userRights"));
  const [invoiceList, setInvoiceList] = useState([]);

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [show, setShow] = useState(false);
  const [showInvoiceReopeningModal, setShowInvoiceReopeningModal] =
    useState(false);
  const [showInvoiceReopeningModalDLM, setShowInvoiceReopeningModalDLM] =
    useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const refInvoices = useRef(null);
  const [distCenterList, setdistCenterList] = useState([]);
  const [shopCodeList, setShopCodeList] = useState([]);
  const [invoiceNumberList, setInvoiceNumberList] = useState([]);
  const [deliveryManList, setDeliveryManList] = useState([]);
  const [disabledDistCenterDDL, setDisabledDistCenterDDL] = useState(false);
  const [disabledShopCodeDDL, setDisabledShopCodeDDL] = useState(false);
  const [disabledInvoiceNumberDDL, setDisabledInvoiceNumberDDL] =
    useState(false);
  const [disabledDeliveryManDDL, setDisabledDeliveryManDDL] = useState(false);
  const [modalDistCenter, setModalDistCenter] = useState(null);
  const [modalShopCode, setModalShopCode] = useState(null);
  const [modalInvoiceNumber, setModalInvoiceNumber] = useState(null);
  const [modalDeliveryManId, setModalDeliveryManId] = useState(null);
  const [reopenBtnDisabled, setReopenBtnDisabled] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const openFilterModal = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  function formRequestObject() {
    let requestData = {
      distCenter: filter.distCenter?.value,
      deliveryManId: filter.deliveryManId,
      vizshopCode: filter.vizshopCode,
      invoiceNo: filter.invoiceNo,
      invoiceStatus: filter.invoiceStatus?.value,
      fromDate: filter.fromDate && formatDate(filter.fromDate),
      toDate: filter.toDate && formatDate(filter.toDate),
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
    };

    return requestData;
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0);
    setfilter({ ...filter, searchText: text, currentPageNo: 0 });
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    };
    fetchReportData(request);
  };

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    getInvoiceList(finalRequest);
  }

  const getInvoiceList = (request) => {
    props.showLoader();
    axios
      .post(`api/InvoiceReOpening/GetReopenInvoicesByDeliveryManId`, request)
      .then(({ data }) => {
        props.hideLoader();

        let _data = data?.response;

        setInvoiceList(_data?.data);
        setTotalRecords(_data?.totalRecords);

        setdistCenterList(
          _data?.distCenters.map(function (item) {
            return {
              value: item.distCenterId,
              label: item.distCenterName,
            };
          })
        );

        setShopCodeList(
          _data?.shopCodes.map(function (item) {
            return {
              value: item,
              label: item,
            };
          })
        );

        setInvoiceNumberList(
          _data?.invoiceNumbers.map(function (item) {
            return {
              value: item,
              label: item,
            };
          })
        );
      })
      .catch(function (error) {
        props.hideLoader();
      });
  };

  const getData = (page) => {
    setCurrentPageNo(page);
    var request = { ...formRequestObject(), pageNo: page };
    fetchReportData(request);
  };

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 };
    fetchReportData(request);
    setShow(false);
  };

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0);
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize };
    fetchReportData(request);
  };

  const handleResetFilters = (resetForm) => {
    resetForm();
    setfilter({
      distCenter: requestPrevious?.distCenter,
      deliveryManId: requestPrevious?.deliveryManId,
      vizshopCode: "",
      invoiceNo: "",
      invoiceStatus: null,
      pageNo: 0,
      fromDate: requestPrevious?.fromDate
        ? new Date(requestPrevious.fromDate)
        : new Date(),
      toDate: requestPrevious?.toDate
        ? new Date(requestPrevious.toDate)
        : new Date(),
      searchText: "",
    });
  };

  const columns = [
    {
      Header: "Dist Center",
      accessor: "distCenterName",
    },

    {
      Header: "Shop Code/Viz-ID",
      accessor: "shopCodes",
    },

    {
      Header: "Customer Name",
      accessor: "vizShopName",
    },

    {
      Header: "Deliveryman Name",
      accessor: "deliveryManName",
    },

    {
      Header: "Invoice No.",
      accessor: "invoiceNo",
    },

    {
      Header: "Invoice Amount",
      accessor: "invoiceAmount",
    },

    {
      Header: "Date (Order Delivery Date)",
      accessor: "orderDeliveryDate",
      Cell: (row) => (
        <div style={{ width: "75px" }}>
          <Moment format="DD/MM/YYYY">{row.value}</Moment>
        </div>
      ),
    },

    {
      Header: "Invoice Status",
      accessor: "invoiceStatus",
    },
  ];

  const invoiceStatusList = [
    {
      value: "Finalized",
      label: "Finalized",
    },
    {
      value: "Unfinalized",
      label: "Unfinalized",
    },
    {
      value: "Credit",
      label: "Credit",
    },
  ];

  useEffect(() => {
    fetchReportData();
  }, []);

  const [invoiceOpeningModalInitialValue, setInvoiceOpeningModalInitialValue] =
    useState({
      distCenter: "",
      shopCode: "",
      invoiceNumber: "",
      deliveryManId: "",
    });

  const invoiceOpeningValidationSchema = Yup.object({
    distCenter: Yup.string().required("Distribution Center is required"),
    shopCode: Yup.string().required("Distribution Center is required"),
    invoiceNumber: Yup.string().required("Distribution Center is required"),
  });

  const invoiceOpeningValidationSchemaForDLM = Yup.object().shape({
    deliveryManId: Yup.string().required("Deliveryman Id is required"),
  });

  const getCenterDeliveryUsers = (id) => {
    axios
      .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
      .then(({ data }) => {
        if (data?.response) {
          setDeliveryManList(
            data.response.map(function (item) {
              return {
                value: item.qrCode,
                label: item.fullname,
              };
            })
          );

          setShowInvoiceReopeningModalDLM(true);
        }
      })
      .catch(function (error) {});
  };

  const invoiceReopeningHandler = (data) => {
    getCenterDeliveryUsers(invoiceOpeningModalInitialValue.distCenter);
    setReopenBtnDisabled(true);
    setShowInvoiceReopeningModalDLM(true);
  };

  const invoiceReopeningSubmitHandler = (data) => {
    setSubmitBtnDisabled(true);

    let req = {
      invoiceNumber: invoiceOpeningModalInitialValue.invoiceNumber,
      deliveryManId: invoiceOpeningModalInitialValue.deliveryManId,
    };

    axios
      .post(`api/InvoiceReOpening/ReOpenedInvoiceUpdate`, req)
      .then(({ data }) => {
        toast.success("Invoice Re-Opened successfully");
        history.goBack();
      })
      .catch(function (error) {});
  };

  const openInvoiceReopeningModal = (isOpen) => {
    setShowInvoiceReopeningModal(isOpen);

    setDisabledDistCenterDDL(distCenterList.length == 1);

    if (distCenterList.length == 1) {
      setModalDistCenter(distCenterList[0]);

      setInvoiceOpeningModalInitialValue({
        distCenter: distCenterList[0].value,
        shopCode: "",
        invoiceNumber: "",
        deliveryManId: "",
      });
    }

    setModalShopCode(null);
    setModalInvoiceNumber(null);
    setModalDeliveryManId(null);

    setDisabledDistCenterDDL(distCenterList.length == 1);
    setDisabledShopCodeDDL(false);
    setDisabledInvoiceNumberDDL(false);
  };

  // For Double Click
  const onRowClick = (e, rowData) => {
    debugger;
    setInvoiceOpeningModalInitialValue({
      distCenter: rowData.distCenterId,
      shopCode: rowData.vizShopCode,
      invoiceNumber: rowData.invoiceNo,
      deliveryManId: rowData.deliveryManId,
    });

    setModalDistCenter({
      label: rowData.distCenterName,
      value: rowData.distCenterId,
    });
    setModalShopCode({
      label: rowData.vizShopCode,
      value: rowData.vizShopCode,
    });
    setModalInvoiceNumber({
      label: rowData.invoiceNo,
      value: rowData.invoiceNo,
    });
    setModalDeliveryManId({
      label: rowData.deliveryManName,
      value: rowData.deliveryManId,
    });

    setShowInvoiceReopeningModal(true);

    setDisabledDistCenterDDL(true);
    setDisabledShopCodeDDL(true);
    setDisabledInvoiceNumberDDL(true);
  };

  return (
    <div>
      <VzPageContainer title="Daily Orders > Invoice Re-Opening">
        <Row>
          <Col md={{ span: 3 }} style={{ float: "left" }}>
            <h4>
              <b>Invoice Re-Opening</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10 }} style={{ float: "left" }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className="m-3"
                variant="outline-primary"
              >
                <i className="fa fa-filter" />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
                width="430px"
                style={{ width: "430px" }}
              >
                <Popover
                  id="popover-contained"
                  style={{ maxWidth: "360px", width: "360px" }}
                >
                  <Popover.Header as="h3">Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filter}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete="off">
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Distribution Center"
                                name="distCenter"
                                options={distCenterList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    distCenter: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.distCenter}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="input"
                                type="text"
                                label="Shop Code / Viz-ID"
                                name="shopCode"
                                handleOnChange={(e) => {
                                  setfilter({
                                    ...filter,
                                    vizshopCode: e,
                                  });
                                }}
                                value={filter.vizshopCode}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="input"
                                type="text"
                                label="Invoice No."
                                name="invoiceNo"
                                handleOnChange={(e) => {
                                  setfilter({
                                    ...filter,
                                    invoiceNo: e,
                                  });
                                }}
                                value={filter.invoiceNo}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Invoice Status"
                                name="invoiceStatus"
                                options={invoiceStatusList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    invoiceStatus: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.invoiceStatus}
                              />
                            </Col>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  type="fromDate"
                                  control="date"
                                  label="From Date"
                                  name="fromDate"
                                  handleOnChange={(value) => {
                                    setfilter({ ...filter, fromDate: value });
                                  }}
                                  value={filter.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  control="date"
                                  type="toDate"
                                  label="To Date"
                                  name="toDate"
                                  handleOnChange={(value) => {
                                    setfilter({ ...filter, toDate: value });
                                  }}
                                  value={filter.toDate}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group">
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "left",
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false);
                                  setTarget(null);
                                }}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "right",
                                }}
                              >
                                Cancel
                              </Link>
                              <div className="float-right">
                                <Button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{
                                    marginRight: "10px",
                                    float: "right",
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          <Col md={{ span: 2 }} style={{ float: "right" }}>
            <Button
              className="form-btn pull-right"
              variant="primary"
              onClick={() => openInvoiceReopeningModal(true)}
              style={{ float: "right", margin: "1px 0px 0px 0px" }}
            >
              <i className="fa fa-recycle" /> Invoice Re-opening
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <ServerSideDataTable
              data={invoiceList ?? []}
              columns={columns}
              getTrProps={(event, data) => onRowClick(event, data)}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
              searchLabel="Search Invoice :"
            />
          </Col>
        </Row>
        {/* //// Invoice Re-Opening Modal */}
        <Modal
          show={showInvoiceReopeningModal}
          onHide={() => {
            setShowInvoiceReopeningModal(false);
            setReopenBtnDisabled(false);
          }}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice Re-Opening
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              //enableReinitialize
              initialValues={invoiceOpeningModalInitialValue}
              validationSchema={invoiceOpeningValidationSchema}
              // validator={() => ({})}
              onSubmit={invoiceReopeningHandler}
            >
              {(formik) => (
                <Form
                  autoComplete="off"
                  encType="multipart/form-data"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <Container>
                    <Row>
                      <Col md={{ span: 7 }} style={{ float: "right" }}>
                        <div className="form-group">
                          <FormikControl
                            formik={formik}
                            control="react-select"
                            label="Distribution Center"
                            name="distCenter"
                            options={distCenterList}
                            isDisabled={disabledDistCenterDDL}
                            isSearchable={true}
                            handleOnChange={(option) => {
                              if (!option || !option.value) {
                                setModalDistCenter(null);
                                setInvoiceOpeningModalInitialValue({
                                  ...invoiceOpeningModalInitialValue,
                                  distCenter: "",
                                });
                              } else {
                                setModalDistCenter(option);
                                setInvoiceOpeningModalInitialValue({
                                  ...invoiceOpeningModalInitialValue,
                                  distCenter: option.value,
                                });
                              }
                            }}
                            value={modalDistCenter}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 7 }} style={{ float: "right" }}>
                        <div className="form-group">
                          <FormikControl
                            formik={formik}
                            control="react-select"
                            label="Shop Code"
                            name="shopCode"
                            options={shopCodeList}
                            isSearchable={true}
                            isDisabled={disabledShopCodeDDL}
                            handleOnChange={(option) => {
                              if (!option || !option.value) {
                                setModalShopCode(null);
                                setInvoiceOpeningModalInitialValue({
                                  ...invoiceOpeningModalInitialValue,
                                  shopCode: "",
                                });
                              } else {
                                setModalShopCode(option);
                                setInvoiceOpeningModalInitialValue({
                                  ...invoiceOpeningModalInitialValue,
                                  shopCode: option.value,
                                });
                              }
                            }}
                            value={modalShopCode}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 7 }} style={{ float: "right" }}>
                        <div className="form-group">
                          <FormikControl
                            formik={formik}
                            control="react-select"
                            label="Invoice No."
                            name="invoiceNumber"
                            options={invoiceNumberList}
                            isDisabled={disabledInvoiceNumberDDL}
                            isSearchable={true}
                            handleOnChange={(option) => {
                              if (!option || !option.value) {
                                setModalInvoiceNumber(null);
                                setInvoiceOpeningModalInitialValue({
                                  ...invoiceOpeningModalInitialValue,
                                  invoiceNumber: "",
                                });
                              } else {
                                setModalInvoiceNumber(option);
                                setInvoiceOpeningModalInitialValue({
                                  ...invoiceOpeningModalInitialValue,
                                  invoiceNumber: option.value,
                                });
                              }
                            }}
                            value={modalInvoiceNumber}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} style={{ textAlign: "center" }}>
                        <Button
                          className="btn btn-default"
                          style={{ marginTop: "10px", marginRight: "20px" }}
                          onClick={() => {
                            setShowInvoiceReopeningModal(false);
                            setReopenBtnDisabled(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <input
                          type="submit"
                          className="btn btn-primary"
                          disabled={reopenBtnDisabled}
                          style={{
                            marginRight: "10px",
                            marginTop: "10px",
                          }}
                          value="Re-Open"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
        {/* //// Invoice Re-Opening Modal For Deliveryman Selection */}
        <Modal
          show={showInvoiceReopeningModalDLM}
          onHide={() => {
            setShowInvoiceReopeningModalDLM(false);
            setSubmitBtnDisabled(false);
            setReopenBtnDisabled(false);
          }}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice Re-Opening
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              initialValues={invoiceOpeningModalInitialValue}
              validationSchema={invoiceOpeningValidationSchemaForDLM}
              // validator={() => ({})}
              onSubmit={invoiceReopeningSubmitHandler}
            >
              {(formik) => (
                <Form
                  autoComplete="off"
                  encType="multipart/form-data"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <Container>
                    <Row>
                      <Col md={{ span: 7 }} style={{ float: "right" }}>
                        <div className="form-group">
                          <FormikControl
                            formik={formik}
                            control="react-select"
                            label="Allocated Deliveryman"
                            name="deliveryManId"
                            options={deliveryManList}
                            isDisabled={disabledDeliveryManDDL}
                            isSearchable={true}
                            handleOnChange={(option) => {
                              if (!option || !option.value) {
                                setModalDeliveryManId(null);
                                invoiceOpeningModalInitialValue.deliveryManId =
                                  "";
                              } else {
                                setModalDeliveryManId(option);
                                invoiceOpeningModalInitialValue.deliveryManId =
                                  option.value;
                              }
                            }}
                            value={modalDeliveryManId}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} style={{ textAlign: "center" }}>
                        <Button
                          className="btn btn-default"
                          style={{ marginTop: "10px", marginRight: "20px" }}
                          onClick={() => {
                            setShowInvoiceReopeningModalDLM(false);
                            setSubmitBtnDisabled(false);
                            setReopenBtnDisabled(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <input
                          type="submit"
                          className="btn btn-primary"
                          disabled={submitBtnDisabled}
                          style={{
                            marginRight: "10px",
                            marginTop: "10px",
                          }}
                          value="Submit"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </VzPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  };
};
export default connect(mapStateToProps, {
  getDropdownList,
  showLoader,
  hideLoader,
})(InvoiceReopeningListingDetail);
