import React, { useRef, useEffect, useState } from "react";
import { VzPageContainer } from "../../../layouts/VzPageContainer";
import { connect } from "react-redux";
import { Modal, Col, Row, Overlay, Button, Popover } from "react-bootstrap";
import ServerSideDataTable from "../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable";
import axios from "../../../apis/Api";
import { getDropdownList } from "../../Shared/dropdownListActions";
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from "../../../Types";
import FormikControl from "../../../layouts/Input/FormikControl";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import storage from "../../../apis/Storage";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useHistory } from "react-router-dom";

export const showLoader = () => async (dispatch) => {
  dispatch(showLoading());
};

export const hideLoader = () => async (dispatch) => {
  dispatch(hideLoading());
};

const InvoiceAllocationListing = (props) => {
  const history = useHistory();

  const userRights = JSON.parse(storage.get("_userRights"));
  const [obidList, setObidList] = useState([]);
  const [allDeliveryManList, setAllDeliveryManList] = useState([]);

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] = useState(
    []
  );
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([]);
  const [deliveryManFilterList, setDeliveryManFilterList] = useState([]);

  const [filter, setfilter] = useState({
    dist: "",
    distCenter: "",
    deliveryMan: "",
    pageNo: 0,
    searchText: "",
    obid: "",
    isExport: false,
  });

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const refInvoices = useRef(null);

  const columns = [
    {
      Header: "Allocated Deliveryman",
      accessor: "allocatedDeliveryMan",
    },

    {
      Header: "Allocated Till No.",
      accessor: "allocatedTillNo",
    },

    {
      Header: "Count Of Invoice",
      accessor: "invoiceCount",
    },

    {
      Header: "",
      accessor: "isSelected",
      Cell: (row) => (
        <>
          <h3
            style={{
              color: "#EA5F2D",
              fontWeight: "bold",
            }}
            onClick={(event) => toggleObid(row, event)}
          >
            {row.value == true ? "-" : "+"}
          </h3>
        </>
      ),
    },
  ];

  const toggleObid = (row, event) => {
    if (obidList.filter((x) => x.isSelected == true).length != 0) return;
    let selectedDLM = row.row.original.allocatedTillNo;
    getObidInvoicesList(selectedDLM);
  };

  const getObidInvoicesList = (dlm) => {
    let requestPrevious = {
      distId: filter?.dist.value,
      distCenterId: filter?.distCenter.value,
      deliveryMan: dlm,
      invoiceNo: filter?.searchText,
    };

    history.push("/InvoiceAllocationListingDetail", { requestPrevious });

    <Link
      to={{
        pathname: "/InvoiceAllocationListingDetail",
        state: { requestPrevious },
      }}
    ></Link>;
  };

  const getObidList = (request) => {
    props.showLoader();
    axios
      .post(`api/ShopOrder/GetInvoiceAllocationList`, request)
      .then(({ data }) => {
        props.hideLoader();
        setObidList(data?.response?.data);
        setTotalRecords(data?.response?.totalRecords);
      })
      .catch(function (error) {
        props.hideLoader();
      });
  };

  const getAllDeliveryUserList = () => {
    axios
      .get(`api/User/GetAllDeliveryUsers`)
      .then(({ data }) => {
        setAllDeliveryManList(data?.response);
      })
      .catch(function (error) {});
  };

  const openFilterModal = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleResetFilters = (resetForm) => {
    resetForm();

    setAddJobDistCenterOptions([]);
    setDeliveryManFilterList([]);
    setfilter({
      dist: null,
      distCenter: null,
      obid: null,
      deliveryMan: null,
      pageNo: 0,
      searchText: "",
      isExport: false,
    });
  };

  function formRequestObject() {
    let requestData = {
      distId: filter.dist?.value,
      distCenterId: filter.distCenter?.value,
      deliveryMan: filter.deliveryMan?.value,
      obid: filter.obid?.value,
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
      isExport: filter.isExport,
    };

    return requestData;
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0);
    setfilter({ ...filter, searchText: text, currentPageNo: 0 });
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    };
    fetchReportData(request);
  };

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    getObidList(finalRequest);
  }

  const getData = (page) => {
    setCurrentPageNo(page);
    var request = { ...formRequestObject(), pageNo: page };
    fetchReportData(request);
  };

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 };
    fetchReportData(request);
    setShow(false);
  };

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0);
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize };
    fetchReportData(request);
  };

  useEffect(() => {
    fetchReportData();
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    );
    getAllDeliveryUserList();
  }, []);

  useEffect(() => {
    if (userRights != null && userRights?.userType == "DistributorUser") {
      var filteredDistList = props.allDistributorList.filter(
        (d) => d.value === userRights?.distributorId.toUpperCase()
      );
      setAddJobDistributorsOptions(filteredDistList);
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          };
        })
      );
    }
  }, [props.allDistributorList]);

  useEffect(() => {
    if (userRights != null && userRights?.userType == "DistributorUser") {
      var assignedDistcenterIds = userRights.distCenterIds.split(",");
      let filteredItems = props.selectedAddJobDistCentersList.filter((item) =>
        assignedDistcenterIds.includes(item.value)
      );
      setAddJobDistCenterOptions(
        filteredItems.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          };
        })
      );
    } else {
      setAddJobDistCenterOptions(
        props.selectedAddJobDistCentersList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          };
        })
      );
    }
  }, [props.selectedAddJobDistCentersList]);

  useEffect(() => {
    if (filter.dist) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filter.dist.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      );
    }
  }, [filter.dist]);

  useEffect(() => {
    if (filter.distCenter) {
      var filteredDM = allDeliveryManList.filter(
        (x) => x.distCenterId == filter.distCenter.value.toLowerCase()
      );
      setDeliveryManFilterList(
        filteredDM.map(function (item) {
          return {
            value: item.id,
            label: item.fullname,
          };
        })
      );
    }
  }, [filter.distCenter]);

  return (
    <div>
      <VzPageContainer title="Invoice Allocation Listing">
        <Row>
          <Col md={{ span: 3 }} style={{ float: "left" }}>
            <h4>
              <b>Invoice Allocation Listing</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10} style={{ float: "right" }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className="m-3"
                variant="outline-primary"
              >
                <i className="fa fa-filter" />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
                width="430px"
                style={{ width: "430px" }}
              >
                <Popover
                  id="popover-contained"
                  style={{ maxWidth: "360px", width: "360px" }}
                >
                  <Popover.Header as="h3">Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filter}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete="off">
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Distributor"
                                name="distributorName"
                                options={addJobDistributorsOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    setfilter({
                                      ...filter,
                                      dist: null,
                                    });
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    dist: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.dist}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Distribution Center"
                                name="distributionID"
                                options={addJobDistCenterOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    distCenter: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.distCenter}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Deliveryman Name"
                                name="deliveryMan"
                                options={deliveryManFilterList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    deliveryMan: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.deliveryMan}
                              />
                            </Col>
                          </Row>
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group">
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "left",
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false);
                                  setTarget(null);
                                }}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "right",
                                }}
                              >
                                Cancel
                              </Link>
                              <div className="float-right">
                                <Button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{
                                    marginRight: "10px",
                                    float: "right",
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} ref={refInvoices}>
            <ServerSideDataTable
              data={obidList ?? []}
              columns={columns}
              getTrProps={(event, data) => {}}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
              searchLabel="Search Invoice :"
            />
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  };
};
export default connect(mapStateToProps, {
  getDropdownList,
  showLoader,
  hideLoader,
})(InvoiceAllocationListing);
