import * as React from "react";
import { Row, Col, Container, FormLabel } from "react-bootstrap";

const SCRCompleteDetailReportPrint = ({ dataModel, innerRef }) => {
  const subHeadingStyle = {
    fontWeight: 700,
  };

  const tableHeaderStyle = { color: "#99A0B5", backgroundColor: "#f4f4f4" };

  const firstColStyle = { width: "180px" };

  const secondColStyle = { width: "180px" };

  const thirdColStyle = { width: "115px" };

  return (
    <>
      <Container
        ref={innerRef}
        style={{ paddingTop: "50px", fontSize: "12px" }}
      >
        <div>
          <Row>
            <Col>
              <FormLabel>Distributor:</FormLabel>
            </Col>
            <Col>{dataModel?.distributorName}</Col>
            <Col>
              <FormLabel>Distribution Center:</FormLabel>
            </Col>
            <Col>{dataModel?.distCenterName}</Col>
            <Col>
              <FormLabel>Date:</FormLabel>
            </Col>
            <Col>{dataModel?.date}</Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>DML User ID:</FormLabel>
            </Col>
            <Col>{dataModel?.dlmUserId}</Col>
            <Col>
              <FormLabel>DML Name:</FormLabel>
            </Col>
            <Col>{dataModel?.dlmName}</Col>
            <Col>
              <FormLabel>DML TILL ID:</FormLabel>
            </Col>
            <Col>{dataModel?.dlmTillId}</Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>Total Invoices in Vizlink:</FormLabel>
            </Col>
            <Col>{dataModel?.totalInvoices}</Col>
            <Col>
              <FormLabel>Total Invoices closed in Vizlink:</FormLabel>
            </Col>
            <Col>{dataModel?.totalInvoicesClosed}</Col>
            <Col>
              <FormLabel>Total Invoices Amount:</FormLabel>
            </Col>
            <Col>{dataModel?.totalInvoicesAmount}</Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>Total Collected Amount:</FormLabel>
            </Col>
            <Col>{dataModel?.totalCollectedAmount}</Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>1-Total Cheque Collected</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Cheque Amount</th>
                    <th className="border-0">Cheque #</th>
                    <th className="border-0">Cheque Date</th>
                    <th className="border-0">Payer Bank</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.totalChequeCollectedList &&
                  dataModel?.totalChequeCollectedList.length > 0 ? (
                    dataModel?.totalChequeCollectedList.map((cheque, j) => (
                      <tr key={cheque}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.chequeNo}</td>
                        <td>{cheque.chequeDate}</td>
                        <td>{cheque.payerBankId}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>2-Total Credit Disbursed</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Due Date</th>
                    <th className="border-0">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.totalCreditDisbursedList &&
                  dataModel?.totalCreditDisbursedList.length > 0 ? (
                    dataModel?.totalCreditDisbursedList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.dueDate}</td>
                        <td>{cheque.amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>3-IBFT</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Dist Bank</th>
                    <th className="border-0">Amount</th>
                    <th className="border-0">Payer Name</th>
                    <th className="border-0">TID #</th>
                    <th className="border-0">Payer Bank</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.ibftList && dataModel?.ibftList.length > 0 ? (
                    dataModel?.ibftList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.distBankId}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.payerName}</td>
                        <td>{cheque.tillId}</td>
                        <td>{cheque.payerBankId}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>4-Collections Via Vizlink</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">MFI</th>
                    <th className="border-0">Total Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.collectionViaVizlink &&
                  dataModel?.collectionViaVizlink.length > 0 ? (
                    dataModel?.collectionViaVizlink.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.mfiType}</td>
                        <td>{cheque.amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
            <Col>
              <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <Col xs={12} md={12}>
                  <h6 style={subHeadingStyle}>a- Vizlink Collection Summary</h6>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  style={{ paddingLeft: "60px", paddingRight: "60px" }}
                >
                  <table className="table table-sm table-bordered">
                    <thead style={tableHeaderStyle}>
                      <tr>
                        <th className="border-0" style={firstColStyle}>
                          Collection
                        </th>
                        <th className="border-0" style={secondColStyle}>
                          Vizlink Till #
                        </th>
                        <th className="border-0" style={thirdColStyle}>
                          Amount Collected
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataModel?.vizlinkCollectionSummary &&
                      dataModel?.vizlinkCollectionSummary.length > 0 ? (
                        dataModel?.vizlinkCollectionSummary.map((item, j) => (
                          <tr key={item.collection}>
                            <td>{item.collection}</td>
                            <td>{item.vizlinkTillNo}</td>
                            <td>{item.amountCollected}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12} style={{ textAlign: "center" }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>5-Outside Vizlink Payment</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">MFI</th>
                    <th className="border-0">Amount</th>
                    <th className="border-0">Payer #</th>
                    <th className="border-0">TID #</th>
                    <th className="border-0">TILL #</th>
                    <th className="border-0">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.outsideVizlinkList &&
                  dataModel?.outsideVizlinkList.length > 0 ? (
                    dataModel?.outsideVizlinkList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.mfiType}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.payerName}</td>
                        <td>{cheque.tId}</td>
                        <td>{cheque.tillId}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>6-RTG</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">RTG</th>
                    <th className="border-0">Reason For RTG</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.rtgList && dataModel?.rtgList.length > 0 ? (
                    dataModel?.rtgList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>7-Market Return</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Market Return Amount</th>
                    <th className="border-0">Reason For RTG</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.marketReturnList &&
                  dataModel?.marketReturnList.length > 0 ? (
                    dataModel?.marketReturnList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>8-Additional Sale</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Original Invoice Amount</th>
                    <th className="border-0">Additional Sale</th>
                    <th className="border-0">Revised Invoice Amount</th>
                    <th className="border-0">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.additionalSaleList &&
                  dataModel?.additionalSaleList.length > 0 ? (
                    dataModel?.additionalSaleList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.orginalInvoiceAmount}</td>
                        <td>{cheque.additionalSales}</td>
                        <td>{cheque.revisedInoviceAmount}</td>
                        <td>{cheque.reasonId}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h6 style={subHeadingStyle}>9-Cash Collection</h6>
            </Col>
            <Col xs={12} md={12}>
              <table className="table table-sm">
                <thead style={tableHeaderStyle}>
                  <tr>
                    <th className="border-0" style={firstColStyle}>
                      Dist Shop Code/Viz ID
                    </th>
                    <th className="border-0" style={secondColStyle}>
                      Shop Name
                    </th>
                    <th className="border-0" style={thirdColStyle}>
                      Invoice #
                    </th>
                    <th className="border-0">Cash Collection</th>
                    <th className="border-0">Reason For Cash Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {dataModel?.cashPaymentList &&
                  dataModel?.cashPaymentList.length > 0 ? (
                    dataModel?.cashPaymentList.map((cheque, j) => (
                      <tr key={cheque.invoiceNumber}>
                        <td>
                          {cheque.distShopCode + " / " + cheque.vizShopCode}
                        </td>
                        <td>{cheque.shopName}</td>
                        <td>{cheque.invoiceNumber}</td>
                        <td>{cheque.amount}</td>
                        <td>{cheque.reason}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default SCRCompleteDetailReportPrint;
