export function formatDate(dateInput) {
  var res = new Date(
    Date.parse(
      new Intl.DateTimeFormat("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(dateInput))
    )
  )
    .toISOString()
    .split("T")[0];
  return res;
}

const removedColumns = (obj, columns) => {
  columns.forEach((column) => delete obj[column]);
  return obj;
};

export const getCustomCloumns = (array, invisibleColumns) => {
  if (invisibleColumns != null && invisibleColumns.length > 0) {
    return array.map((row) => {
      return removedColumns(row, invisibleColumns);
    });
  } else return array;
};

export const getAdvertisementStatus = (status) => {
  switch (status) {
    case "Create":
      return 1;
    case "Active":
      return 2;
    case "Complete":
      return 3;
    case "Terminated":
      return 4;
    case "Expansion":
      return 5;
    default:
      return 1;
  }
};

export const getAdvertisementStatusName = (value) => {
  switch (value.toString()) {
    case "1":
      return "Create";
    case "2":
      return "Active";
    case "3":
      return "Complete";
    case "4":
      return "Terminated";
    case "5":
      return "Expansion";
    default:
      return "Create";
  }
};

export const getNotificationSchedularName = (value) => {
  switch (value.toString()) {
    case "1":
      return "Create";
    case "2":
      return "Active";
    case "3":
      return "Complete";
    default:
      return "Create";
  }
};

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

export const getKYCApprovalStatus = (s, v) => {
  if (s && v) return "Approved";
  else return "Rejected";
};
