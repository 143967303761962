import { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import FormikControl from "../../../../layouts/Input/FormikControl";
import {
  getSCRSummaryReport,
  finalizeSCRSummaryReport,
} from "./SCRSummaryReportActions";
import Moment from "react-moment";
import ReactToPrint from "react-to-print";
import SCRSummaryReportPrint from "./SCRSummaryReportPrint";
import { isPermittedPage } from "../../../../apis/Utility";
import { useHistory } from "react-router-dom";

const SCRSummaryReport = (props) => {
  const hrStyle = {
    "margin-top": -5,
    border: "none",
    height: "1px",
  };

  const textOverflow = {
    overflow: "hidden",
    display: "inline-block",
  };

  let history = useHistory();
  const { id, date } = props.location.state.params;
  const componentRef = useRef();

  const [isView, setIsView] = useState(true);
  const [totalVizLinkCollectionSum, setTotalVizLinkCollectionSum] = useState(0);
  const [totalOutSideCollectionSum, settoTotalOutSideCollectionSum] =
    useState(0);
  const [totalBankCollection, setTotalBankCollection] = useState(0);

  const [currentEntity, setCurrentEntity] = useState({
    dlmUserId: "",
    date: null,

    totalVizLinkCollection: [],
    totalOutSideCollection: [],
    totalBankCollection: [],

    totalCashCollected_Edited: 0,
    totalCreditDisbursed_Edited: 0,
    totalRTG_Edited: 0,
    totalMarketReturn_Edited: 0,
    totalAdditionalSale_Edited: 0,
  });

  useEffect(() => {
    let requestData = { deliveryUserId: id, OrderDeliveryDate: date };

    props.getSCRSummaryReport(requestData);
  }, []);

  const ArraySum = (array, setter) => {
    if (array && array.length > 0) {
      let totalSum = 0;
      array.map((data) => {
        totalSum = totalSum + data.amount;
      });
      setter(totalSum);
    }
  };

  useMemo(() => {
    if (props.report) {
      currentEntity.dlmUserId = props.report.dlmUserId;
      currentEntity.date = props.report.date;

      currentEntity.totalVizLinkCollection =
        props.report.totalVizLinkCollection;
      currentEntity.totalOutSideCollection =
        props.report.totalOutSideCollection;
      currentEntity.totalBankCollection = props.report.totalBankCollection;

      currentEntity.totalCashCollected_Edited =
        props.report.totalCashCollected_Edited;
      currentEntity.totalCreditDisbursed_Edited =
        props.report.totalCreditDisbursed_Edited;
      currentEntity.totalRTG_Edited = props.report.totalRTG_Edited;
      currentEntity.totalMarketReturn_Edited =
        props.report.totalMarketReturn_Edited;
      currentEntity.totalAdditionalSale_Edited =
        props.report.totalAdditionalSale_Edited;

      ArraySum(
        currentEntity.totalVizLinkCollection,
        setTotalVizLinkCollectionSum
      );
      ArraySum(
        currentEntity.totalOutSideCollection,
        settoTotalOutSideCollectionSum
      );
      ArraySum(currentEntity.totalBankCollection, setTotalBankCollection);
    }
  }, [props.report]);

  const FindArrayElement = (array, type) => {
    if (array && array.length > 0) {
      return array.findIndex((element) => {
        return element.type === type;
      });
    }
    return -1;
  };

  const UpdateArray = (array, event) => {
    if (array && array.length > 0 && isNumeric(event.target.value) && event.target.value >= 0) {
      let index = FindArrayElement(array, event.target.name);
      if (index > -1) {
        array[index].editedAmount = event.target.value;
      }
    }
  };

  function isNumeric(s) {
    return !isNaN(s - parseFloat(s));
  }

  const handleVizlinkCollectionChange = (event) => {
    UpdateArray(currentEntity.totalVizLinkCollection, event);
  };

  const handleOutsideCollectionChange = (event) => {
    UpdateArray(currentEntity.totalOutSideCollection, event);
  };

  const handleBankCollectionChange = (event) => {
    UpdateArray(currentEntity.totalBankCollection, event);
  };

  const handleEditOrView = () => {
    setIsView(!isView);
  };

  const handleSubmit = async () => {
    await props.finalizeSCRSummaryReport(currentEntity);
    setIsView(true);
    props.getSCRSummaryReport({
      deliveryUserId: id,
      OrderDeliveryDate: date,
    });
  };

  const renderTransactionRow = (data, onChangeHandler) => {
    if (data) {
      return (
        <Row style={{ "margin-top": -10 }}>
          <Col md={6}>
            <label className="col-form-label pl-4">
              Total Collection Via {data.type}
            </label>
          </Col>
          <Col md={2} style={{ "margin-top": -27 }}>
            <FormikControl
              className="form-control form-control-sm text-right"
              readOnly={isView}
              control="input"
              type="number"
              name={data.type}
              min={0}
              onChange={onChangeHandler}
              placeHolder={data.editedAmount}
            />
          </Col>
          <Col md={2} className="text-center">
            {data.amount}
          </Col>
        </Row>
      );
    }
  };

  const renderSummaryColumn = (name, value) => {
    return (
      <div className="row align-items-center">
        <div className="col-7">
          <label className="col-form-label">{name}:</label>
        </div>
        <div className="col-5">
          <span style={textOverflow}>{value ? value : ""}</span>
        </div>
      </div>
    );
  };

  const renderCollectionHeading = (name, value) => {
    return (
      <Row>
        <Col md={10}>
          <label className="col-form-label">
            <b>{name}</b>
          </label>
        </Col>
        <Col md={2} className="text-center">
          <b>{value}</b>
        </Col>
      </Row>
    );
  };

  return (
    <Row>
      <div className="card mt-3" ref={componentRef}>
        <div className="card-body">
          {/* ============ || Main Summary || ============*/}
          <section>
            <Row>
              <Col>
                {renderSummaryColumn("Distributor", props.report.distributor)}
              </Col>
              <Col>
                {renderSummaryColumn(
                  "Distribution Center",
                  props.report.distCenter
                )}
              </Col>
              <Col>
                {renderSummaryColumn(
                  "Date",
                  <Moment format="DD/MM/YYYY">
                    {props.report.date ? props.report.date : new Date()}
                  </Moment>
                )}
              </Col>
            </Row>

            <Row style={{ "margin-top": -15 }}>
              <Col>
                {renderSummaryColumn("DLM User ID", props.report.dlmUserName)}
              </Col>
              <Col>{renderSummaryColumn("DLM Name", props.report.dlmName)}</Col>
              <Col>
                {renderSummaryColumn("DLM Till ID", props.report.dlmTillId)}
              </Col>
            </Row>

            <Row style={{ "margin-top": -15 }}>
              <Col>
                {renderSummaryColumn(
                  "Total Invoices in Vizlink",
                  props.report.totalVizInvoices
                )}
              </Col>
              <Col>
                {renderSummaryColumn(
                  "Total Invoices closed in Vizlink",
                  props.report.totalClosedVizInvoices
                )}
              </Col>
              <Col>
                {renderSummaryColumn(
                  "Total Invoices Amount",
                  props.report.totalInvoicesAmount
                )}
              </Col>
            </Row>

            <Row style={{ "margin-top": -15 }}>
              <Col sm={4} md={4}>
                {renderSummaryColumn(
                  "Total Collected Amount",
                  props.report.totalCollectedAmount
                )}
              </Col>
            </Row>

            <Row>
              <div className="bg-gray">
                <Row>
                  <Col sm={6} md={6}>
                    <label className="col-form-label">Description</label>
                  </Col>
                  <Col sm={2} md={2} className="text-center">
                    <label className="col-form-label">Mode wise Breakup</label>
                  </Col>
                  <Col sm={2} md={2} className="text-center">
                    <label className="col-form-label">Mode wise Breakup</label>
                  </Col>
                  <Col sm={2} md={2} className="text-center">
                    <label className="col-form-label">Amount</label>
                  </Col>
                </Row>
              </div>
            </Row>
          </section>

          {/* ============ || Form Data || ============*/}
          <section>
            <Row className="mt-2">
              <Formik enableReinitialize initialValues={currentEntity}>
                {(formik) => (
                  <Form autoComplete="off">
                    {renderCollectionHeading(
                      "Total Collection in Vizlink (M2D)",
                      totalVizLinkCollectionSum
                    )}
                    {currentEntity.totalVizLinkCollection
                      ? currentEntity.totalVizLinkCollection.map(
                        (data, index) => {
                          return (
                            <div key={index}>
                              {renderTransactionRow(
                                data,
                                handleVizlinkCollectionChange
                              )}
                            </div>
                          );
                        }
                      )
                      : null}

                    <hr style={hrStyle} />
                    {renderCollectionHeading(
                      "Total Outside Vizlink",
                      totalOutSideCollectionSum
                    )}
                    {currentEntity.totalOutSideCollection
                      ? currentEntity.totalOutSideCollection.map(
                        (data, index) => {
                          return (
                            <div key={index}>
                              {renderTransactionRow(
                                data,
                                handleOutsideCollectionChange
                              )}
                            </div>
                          );
                        }
                      )
                      : null}

                    <hr style={hrStyle} />
                    {renderCollectionHeading(
                      "Total Collection in Bank",
                      totalBankCollection
                    )}
                    {currentEntity.totalBankCollection
                      ? currentEntity.totalBankCollection.map((data, index) => {
                        return (
                          <div key={index}>
                            {renderTransactionRow(
                              data,
                              handleBankCollectionChange
                            )}
                          </div>
                        );
                      })
                      : null}

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={6} md={6}>
                        <label className="col-form-label">
                          <b>Total Cash Collected</b>
                        </label>
                      </Col>
                      <Col sm={2} md={2} style={{ "margin-top": -23 }}>
                        <FormikControl
                          className="form-control form-control-sm text-right"
                          formik={formik}
                          readOnly={isView}
                          control="input"
                          type="number"
                          name="totalCashCollected"
                          handleOnChange={(e) => {
                            if (e >= 0) {
                              currentEntity.totalCashCollected_Edited = e;
                            }
                          }}
                          value={currentEntity.totalCashCollected_Edited}
                        />
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        {props.report ? props.report.totalCashCollected : 0}
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        <b>
                          {props.report ? props.report.totalCashCollected : 0}
                        </b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={6} md={6}>
                        <label className="col-form-label">
                          <b>Total Credit Disbursed</b>
                        </label>
                      </Col>
                      <Col sm={2} md={2} style={{ "margin-top": -23 }}>
                        <FormikControl
                          className="form-control form-control-sm text-right"
                          formik={formik}
                          readOnly={isView}
                          control="input"
                          type="number"
                          name="totalCreditDisbursed"
                          handleOnChange={(e) => {
                            if (e >= 0) {
                              currentEntity.totalCreditDisbursed_Edited = e;
                            }
                          }}
                          value={currentEntity.totalCreditDisbursed_Edited}
                        />
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        {props.report ? props.report.totalCreditDisbursed : 0}
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        <b>
                          {props.report ? props.report.totalCreditDisbursed : 0}
                        </b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={6} md={6}>
                        <label className="col-form-label">
                          <b>Total RTG</b>
                        </label>
                      </Col>
                      <Col sm={2} md={2} style={{ "margin-top": -23 }}>
                        <FormikControl
                          className="form-control form-control-sm text-right"
                          formik={formik}
                          readOnly={isView}
                          control="input"
                          type="number"
                          name="totalRTG"
                          handleOnChange={(e) => {
                            if (e >= 0) {
                              currentEntity.totalRTG_Edited = e;
                            }
                          }}
                          value={currentEntity.totalRTG_Edited}
                        />
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        {props.report ? props.report.totalRTG : 0}
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        <b>{props.report ? props.report.totalRTG : 0}</b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={6} md={6}>
                        <label className="col-form-label">
                          <b>Total Market Return</b>
                        </label>
                      </Col>
                      <Col md={2} style={{ "margin-top": -23 }}>
                        <FormikControl
                          className="form-control form-control-sm text-right"
                          formik={formik}
                          readOnly={isView}
                          control="input"
                          type="number"
                          name="totalMarketReturn"
                          handleOnChange={(e) => {
                            if (e >= 0) {
                              currentEntity.totalMarketReturn_Edited = e;
                            }
                          }}
                          value={currentEntity.totalMarketReturn_Edited}
                        />
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        {props.report ? props.report.totalMarketReturn : 0}
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        <b>
                          {props.report ? props.report.totalMarketReturn : 0}
                        </b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={6} md={6}>
                        <label className="col-form-label">
                          <b>Total Additional Sale</b>
                        </label>
                      </Col>
                      <Col sm={2} md={2} style={{ "margin-top": -23 }}>
                        <FormikControl
                          className="form-control form-control-sm text-right"
                          formik={formik}
                          readOnly={isView}
                          control="input"
                          type="number"
                          name="totalAdditionalSale"
                          handleOnChange={(e) => {
                            if (e >= 0) {
                              currentEntity.totalAdditionalSale_Edited = e;
                            }
                          }}
                          value={currentEntity.totalAdditionalSale_Edited}
                        />
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        {props.report ? props.report.totalAdditionalSale : 0}
                      </Col>
                      <Col
                        sm={2}
                        md={2}
                        className="text-center"
                        style={{ "margin-top": 5 }}
                      >
                        <b>
                          {props.report ? props.report.totalAdditionalSale : 0}
                        </b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={10} md={10}>
                        <label className="col-form-label">
                          <b>Outstanding Amount</b>
                        </label>
                      </Col>
                      <Col sm={2} md={2} className="text-center">
                        <b>
                          {props.report ? props.report.outstandingAmount : 0}
                        </b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col>
                        <label className="col-form-label">
                          <b>Total Receivable from Deliveryman</b>
                        </label>
                      </Col>
                    </Row>
                    <Row style={{ "margin-top": -15 }}>
                      <Col sm={8} md={8}>
                        <label className="col-form-label pl-4">Cash</label>
                      </Col>
                      <Col sm={2} md={2} className="text-center">
                        <b>
                          {props.report
                            ? props.report.totalReciveableFromDeliveryman
                              ? props.report.totalReciveableFromDeliveryman.cash
                              : 0
                            : 0}
                        </b>
                      </Col>
                    </Row>
                    <Row style={{ "margin-top": -15 }}>
                      <Col sm={8} md={8}>
                        <label className="col-form-label pl-4">
                          Cheque - (Total No. of Cheques ={" "}
                          {props.report
                            ? props.report.totalReciveableFromDeliveryman
                              ? props.report.totalReciveableFromDeliveryman
                                .totalNoOfChecque
                              : 0
                            : 0}
                          )
                        </label>
                      </Col>
                      <Col sm={2} md={2} className="text-center">
                        <b>
                          {props.report
                            ? props.report.totalReciveableFromDeliveryman
                              ? props.report.totalReciveableFromDeliveryman
                                .cheque
                              : 0
                            : 0}
                        </b>
                      </Col>
                    </Row>

                    <hr style={hrStyle} />
                    <Row style={{ "margin-top": -10 }}>
                      <Col sm={8} md={10}>
                        <label className="col-form-label">
                          <b>Total Cash Deposit in Bank</b>
                        </label>
                      </Col>
                      <Col sm={2} md={2} className="text-center">
                        <b>
                          {" "}
                          {props.report
                            ? props.report.totalCashInBankDeposit
                            : 0}
                        </b>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Row>
          </section>

          {/* ============ || Footer Buttons || ============*/}
          <section>
            <Row>
              <div className="mt-4">
                <ReactToPrint
                  trigger={() => (
                    <button
                      style={{ "min-inline-size": 100 }}
                      className="btn btn-primary mr-2"
                      type="button"
                    >
                      Print
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <button
                  style={{ "min-inline-size": 100 }}
                  className="btn btn-primary float-right"
                  type="button"
                  disabled={props.report ? props.report.isFinalized : true}
                  onClick={handleSubmit}
                >
                  Finalize
                </button>
                <button
                  style={{ "min-inline-size": 100 }}
                  className="btn btn-default float-right mr-2"
                  type="button"
                  onClick={() => { history.goBack() }}
                >
                  Close
                </button>
                {isPermittedPage("Edit", props.actions, props.userRole) && (
                  <button
                    style={{ "min-inline-size": 100 }}
                    className="btn btn-success float-right mr-2"
                    disabled={props.report ? props.report.isFinalized : true}
                    onClick={handleEditOrView}
                    type="button"
                  >
                    {isView ? "Edit" : "View"}
                  </button>
                )}
              </div>
            </Row>
          </section>

          <div style={{ display: "none" }}>
            <SCRSummaryReportPrint
              dataModel={props.report ? props.report : null}
              innerRef={(el) => (componentRef.current = el)}
            />
          </div>
        </div>
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    report: state.SCRSummaryReport,
  };
};
export default connect(mapStateToProps, {
  getSCRSummaryReport,
  finalizeSCRSummaryReport,
})(SCRSummaryReport);
