import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { VzPageContainer } from "../../../layouts/VzPageContainer";
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from "react-bootstrap";
import Moment from "react-moment";
import axios from "../../../apis/Api";
import { Link, Redirect } from "react-router-dom";
import FormikControl from "../../../layouts/Input/FormikControl";
import { Formik, Form } from "formik";
import ServerSideDataTable from "../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable";
import { getKYCApprovalStatus } from "../../../utilities/FUNCTIONS";

const KycApprovalListForMuawin = (props) => {
  const [kycList, setKycList] = useState([]);

  // pagination states
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const openFilterModal = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleResetFilters = (resetForm) => {
    resetForm();

    setfilter(filterInitialValues);
  };

  const filterInitialValues = {
    id: "",
    vizId: "",
    cnic: "",
    contactNo: "",
    status: "",
    vizlinkKycStatus: "",
    pageNo: 0,
    searchText: "",
  };

  const [filter, setfilter] = useState(filterInitialValues);

  const columns = [
    {
      Header: "VIZ ID",
      accessor: "vizId",
    },
    {
      Header: "CNIC",
      accessor: "cnic",
    },
    {
      Header: "Contact No.",
      accessor: "contactNo",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: (row) => (
        <div style={{ width: "75px" }}>
          <Moment format="DD/MM/YYYY">{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original } }) => (
        <div>
          {getKYCApprovalStatus(original.status, original.vizlinkKycStatus)}
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ row: { original } }) => (
        <div>
          <button
            title="Toggle User Active Status"
            onClick={() => toggleActive(original.id, original.vizlinkKycStatus)}
            className=" btn btn-sm"
            style={{ color: "#e84d15", backgroundColor: "transparent" }}
          >
            <i
              className={
                original.status && original.vizlinkKycStatus
                  ? "fas fa-toggle-on fa-2x"
                  : "fas fa-toggle-off fa-2x"
              }
            ></i>
          </button>
        </div>
      ),
    },
  ];

  const toggleActive = (id, status) => {
    setfilter({ ...filter, id: id, vizlinkKycStatus: status });
    var request = {
      ...formRequestObject(),
      id: id,
      vizlinkKycStatus: status,
    };

    axios
      .put(`api/KycApprovalListForMuawin/UpdateKYCApprovalStatus`, request)
      .then(({ data }) => {
        setKycList(data?.response?.data);
        setTotalRecords(data?.response?.totalRecords);
      })
      .catch(function (error) {});
  };

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 };
    fetchReportData(request);
    setShow(false);
  };

  const getKycList = (request) => {
    axios
      .post(`api/KycApprovalListForMuawin/GetKycApprovalListForMuawin`, request)
      .then(({ data }) => {
        setKycList(data?.response?.data);
        setTotalRecords(data?.response?.totalRecords);
      })
      .catch(function (error) {});
  };

  const searchChanged = (text) => {
    setCurrentPageNo(0);
    setfilter({ ...filter, searchText: text });
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    };
    fetchReportData(request);
  };

  function formRequestObject() {
    let requestData = {
      id: filter.id,
      vizId: filter.vizId,
      cnic: filter.cnic,
      contactNo: filter.contactNo,
      status: filter.status?.value,
      vizlinkKycStatus: filter.vizlinkKycStatus?.value,
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
    };

    return requestData;
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    getKycList(finalRequest);
  }

  const getData = (page) => {
    setCurrentPageNo(page);
    setfilter({ ...filter, pageNo: page });
    var request = { ...formRequestObject(), pageNo: page };
    fetchReportData(request);
  };

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0);
    setCurrentPageSize(pageSize);
    setfilter({ ...filter, pageNo: pageSize });
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize };
    fetchReportData(request);
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  const statusOptions = [
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  return (
    <>
      <VzPageContainer title="KYC > KYC Approval Listing-Muawin">
        <Row>
          <Col style={{ float: "left" }}>
            <h4>
              <b>KYC Approval Listing - Muawin</b>
            </h4>
            <br />
          </Col>
        </Row>

        <Row>
          <Col md={10} style={{ float: "right" }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className="m-3"
                variant="outline-primary"
              >
                <i className="fa fa-filter" />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
                width="430px"
                style={{ width: "430px" }}
              >
                <Popover
                  id="popover-contained"
                  style={{ maxWidth: "360px", width: "360px" }}
                >
                  <Popover.Header as="h3">Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filter}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete="off">
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="input"
                                type="vizId"
                                label="Viz Id"
                                name="vizId"
                                handleOnChange={(e) => {
                                  setfilter({
                                    ...filter,
                                    vizId: e,
                                  });
                                }}
                                value={filter.vizId}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="input"
                                type="cnic"
                                label="CNIC"
                                name="cnic"
                                handleOnChange={(e) => {
                                  setfilter({
                                    ...filter,
                                    cnic: e,
                                  });
                                }}
                                value={filter.cnic}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="input"
                                type="contactNo"
                                label="Contact No."
                                name="contactNo"
                                handleOnChange={(e) => {
                                  setfilter({
                                    ...filter,
                                    contactNo: e,
                                  });
                                }}
                                value={filter.contactNo}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Status"
                                name="status"
                                options={statusOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    status: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.status}
                              />
                            </Col>
                          </Row>
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group">
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "left",
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false);
                                  setTarget(null);
                                }}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "right",
                                }}
                              >
                                Cancel
                              </Link>
                              <div className="float-right">
                                <Button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{
                                    marginRight: "10px",
                                    float: "right",
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ServerSideDataTable
              data={kycList ?? []}
              columns={columns}
              getTrProps={(event, data) => {}}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </VzPageContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(KycApprovalListForMuawin);
