import { GET_SCR_COMPLETE_DETAIL } from "../../../../Types";

const SCRCompleteDetailReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SCR_COMPLETE_DETAIL:
      return action.payload;
    default:
      return state;
  }
};
export default SCRCompleteDetailReportReducer;
