///// For Auth Reducer
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
//// Main Informations
export const ADD_MAININFORMATIONS = "ADD_MAININFORMATIONS";
export const GET_MAININFORMATIONS = "GET_MAININFORMATIONS";
export const DELETE_MAININFORMATIONS = "DELETE_MAININFORMATIONS";
export const UPDATE_MAININFORMATIONS = "UPDATE_MAININFORMATIONS";
export const EMPTY_MAININFORMATIONS = "EMPTY_MAININFORMATIONS";
//// MANAGE PRINCIPALS
export const ADD_PRINCIPALS = "ADD_PRINCIPALS";
export const GET_PRINCIPALS = "GET_PRINCIPALS";
export const DELETE_PRINCIPALS = "DELETE_PRINCIPALS";
export const UPDATE_PRINCIPALS = "UPDATE_PRINCIPALS";
export const EMPTY_PRINCIPALS = "EMPTY_PRINCIPALS";
//// MANAGE DISTRIBUTION PRINCIPALS
export const ADD_DISTRIBUTORPRINCIPALS = "ADD_DISTRIBUTORPRINCIPALS";
export const GET_DISTRIBUTORPRINCIPALS = "GET_DISTRIBUTORPRINCIPALS";
export const DELETE_DISTRIBUTORPRINCIPALS = "DELETE_DISTRIBUTORPRINCIPALS";
export const UPDATE_DISTRIBUTORPRINCIPALS = "UPDATE_DISTRIBUTORPRINCIPALS";
export const EMPTY_DISTRIBUTORPRINCIPALS = "EMPTY_DISTRIBUTORPRINCIPALS";
//// Bank Details
export const ADD_BANKDETAILS = "ADD_BANKDETAILS";
export const GET_BANKDETAILS = "GET_BANKDETAILS";
export const DELETE_BANKDETAILS = "DELETE_BANKDETAILS";
export const UPDATE_BANKDETAILS = "UPDATE_BANKDETAILS";
export const EMPTY_BANKDETAILS = "EMPTY_BANKDETAILS";
//// Distribution Centers
export const ADD_DISTRIBUTIONCENTERS = "ADD_DISTRIBUTIONCENTERS";
export const GET_DISTRIBUTIONCENTERS = "GET_DISTRIBUTIONCENTERS";
export const DELETE_DISTRIBUTIONCENTERS = "DELETE_DISTRIBUTIONCENTERS";
export const UPDATE_DISTRIBUTIONCENTERS = "UPDATE_DISTRIBUTIONCENTERS";
export const GET_DISTRIBUTIONCENTERSLIST = "GET_DISTRIBUTIONCENTERSLIST";
export const EMPTY_DISTRIBUTIONCENTERSLIST = "EMPTY_DISTRIBUTIONCENTERSLIST";
//// MFI_Details
export const ADD_MFI_DETAILS = "ADD_MFI_DETAILS";
export const GET_MFI_DETAILS = "GET_MFI_DETAILS";
export const DELETE_MFI_DETAILS = "DELETE_MFI_DETAILS";
export const UPDATE_MFI_DETAILS = "UPDATE_MFI_DETAILS";
export const GET_MFI_DETAILSLIST = "GET_MFI_DETAILSLIST";
export const EMPTY_MFI_DETAILSLIST = "EMPTY_MFI_DETAILSLIST";
//// Distributor Induction Users
export const ADD_DI_USERS = "ADD_DI_USERS";
export const GET_DI_USERS = "GET_DI_USERS";
export const DELETE_DI_USERS = "DELETE_DI_USERS";
export const UPDATE_DI_USERS = "UPDATE_DI_USERS";
export const EMPTY_DI_USERS = "EMPTY_DI_USERS";
//// QR/Till DETAILS
export const ADD_QRTILLDETAILS = "ADD_QRTILLDETAILS";
export const GET_QRTILLDETAILS = "GET_QRTILLDETAILS";
export const DELETE_QRTILLDETAILS = "DELETE_QRTILLDETAILS";
export const UPDATE_QRTILLDETAILS = "UPDATE_QRTILLDETAILS";
export const EMPTY_QRTILLDETAILS = "EMPTY_QRTILLDETAILS";
export const UPLOAD_QRTILLDETAILS = "UPLOAD_QRTILLDETAILS";
//// QR/Till LIST
// export const ADD_QRTILLDETAILS = 'ADD_QRTILLDETAILS'
export const GET_QRTILL_LIST = "GET_QRTILL_LIST";
export const GET_QRTILL_MFI_FILTER_LIST = "GET_QRTILL_MFI_FILTER_LIST";
export const GET_QRTILL_CENTER_FILTER_LIST = "GET_QRTILL_CENTER_FILTER_LIST";

//// QR/Till Create Delivery User
export const GET_DELIVERY_USER_LIST = "GET_DELIVERY_USER_LIST";
export const CREATE_DELIVERY_USER = "CREATE_DELIVERY_USER";

//// Till Assignment List
export const GET_TILL_ASSSIGNMENT_LIST = "GET_TILL_ASSSIGNMENT_LIST";

//// List of Distributors
export const GET_DISTRIBUTORSLIST = "GET_DISTRIBUTORSLIST";
export const DELETE_DISTRIBUTOR = "DELETE_DISTRIBUTOR";
export const EMPTY_DISTRIBUTOR = "EMPTY_DISTRIBUTOR";
////List of MFIs
export const GET_MFIS = "GET_MFIS";
/// Finalize Distributor Summary
export const FINALIZE_DISTRIBUTOR_SUMMARY = "FINALIZE_DISTRIBUTOR_SUMMARY";

//// JOBS List
export const ADD_JOBS = "ADD_JOBS";
export const GET_JOBS = "GET_JOBS";
export const DELETE_JOBS = "DELETE_JOBS";
export const UPDATE_JOBS = "UPDATE_JOBS";
export const EMPTY_JOBS = "EMPTY_JOBS";
export const UPLOAD_JOBS = "UPLOAD_JOBS";

//// JOB DETAILS
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const UPDATE_JOB_DETAILS = "UPDATE_JOB_DETAILS";
export const UPDATE_JOB_RSO_DETAILS = "UPDATE_JOB_RSO_DETAILS";
export const SEARCH_VIZLINK_SHOPS = "SEARCH_VIZLINK_SHOPS";
export const EMPTY_VIZLINK_SHOPS = "EMPTY_VIZLINK_SHOPS";
export const LINK_DIST_SHOP_TO_VIZ_SHOP = "LINK_DIST_SHOP_TO_VIZ_SHOP";
export const UNLINK_DIST_SHOP_TO_VIZ_SHOP = "UNLINK_DIST_SHOP_TO_VIZ_SHOP";

//// VIZLINKSHOPS
export const GET_VIZLINKSHOPS = "GET_VIZLINKSHOPS";

/// RSO Jobs
export const GET_RSO_JOBS = "GET_RSO_JOBS";
export const ADD_RSO_JOBS = "ADD_RSO_JOBS";

/// Vizlink Agent
export const GET_VIZLINK_AGENT = "GET_VIZLINK_AGENT";
export const ADD_VIZLINK_AGENT = "ADD_VIZLINK_AGENT";
export const DELETE_VIZLINK_AGENT = "DELETE_VIZLINK_AGENT";
export const UPDATE_VIZLINK_AGENT = "UPDATE_VIZLINK_AGENT";
export const EMPTY_VIZLINK_AGENT = "EMPTY_VIZLINK_AGENT";
export const UPLOAD_VIZLINK_AGENT = "UPLOAD_VIZLINK_AGENT";
//// Data for Dropdown Lists

export const RESET_DDL = "RESET_DDL";
export const GET_DISTRIBUTOR_DDL = "GET_DISTRIBUTOR_DDL";
export const GET_RSOS_DDL = "GET_RSOS_DDL";
export const GET_JOBS_DISTRIBUTOR_DDL = "GET_JOBS_DISTRIBUTOR_DDL";
export const GET_DISTRIBUTIONCENTER_DDL = "GET_DISTRIBUTIONCENTER_DDL";
export const GET_ALL_DISTRIBUTION_CENTERS_DDL =
  "GET_ALL_DISTRIBUTION_CENTERS_DDL";
export const GET_VLC_TICKET_CATEGORIES_DDL = "GET_VLC_TICKET_CATEGORIES_DDL";
export const GET_VIZ_IDS_DDL = "GET_VIZ_IDS_DDL";
export const GET_DELIVERYMAN_TILL_DDL = "GET_DELIVERYMAN_TILL_DDL";
export const GET_DISTRIBUTOR_ALL_CATEGORY_INBOX =
  "GET_DISTRIBUTOR_ALL_CATEGORY_INBOX";
export const GET_DISTRIBUTOR_INFO_CATEGORY_INBOX =
  "GET_DISTRIBUTOR_INFO_CATEGORY_INBOX";
export const GET_DISTRIBUTOR_ORDER_CATEGORY_INBOX =
  "GET_DISTRIBUTOR_ORDER_CATEGORY_INBOX";
export const GET_DISTRIBUTOR_COMPLAINT_CATEGORY_INBOX =
  "GET_DISTRIBUTOR_COMPLAINT_CATEGORY_INBOX";
export const GET_DISTRIBUTOR_OTHER_CATEGORY_INBOX =
  "GET_DISTRIBUTOR_OTHER_CATEGORY_INBOX";
export const GET_DISTRIBUTOR_INBOX_ACTIVE_TAB =
  "GET_DISTRIBUTOR_INBOX_ACTIVE_TAB";

export const GET_ADDJOB_DISTRIBUTIONCENTER_DDL =
  "GET_ADDJOB_DISTRIBUTIONCENTER_DDL";
export const GET_BANKS = "GET_BANKS";
export const GET_CITIES = "GET_CITIES";
export const GET_BUSINESS_ENTITIES = "GET_BUSINESS_ENTITIES";
export const GET_RSO_USERS = "GET_RSO_USERS";
export const GET_AREAS = "GET_AREAS";
export const GET_MFI_DDL = "GET_MFI_DDL";
export const GET_PAYMENT_TYPES_DDL = "GET_PAYMENT_TYPES_DDL";
export const GET_BUSINESS_ENTITY_TYPE_DDL = "GET_BUSINESS_ENTITY_TYPE_DDL";

//// Distributor Induction Users
export const ADD_USERS = "ADD_USERS";
export const ADD_Notification_Schedular = "ADD_Notification_Schedular";
export const UPDATE_Notification_Schedular = "UPDATE_Notification_Schedular";
export const DEL_Notification_Schedular = "DEL_Notification_Schedular";
export const GET_Notification_Schedular = "GET_Notification_Schedular";
export const GET_USERS = "GET_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const RESET_USERS = "RESET_USERS";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_ACTIVE_STATUS = "UPDATE_USER_ACTIVE_STATUS";
//// Manage Daily Orders
export const ADD_ORDERS = "ADD_ORDERS";
export const ADD_Unilever_ORDERS = "ADD_Unilever_ORDERS";
export const ADD_Master_ORDERS = "ADD_Master_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";
export const GET_ORDERS = "GET_ORDERS";

//// Manage Order Details
export const GET_MANAGE_ORDER_DETAILS = "GET_MANAGE_ORDER_DETAILS";
export const DELETE_MANAGE_ORDER_DETAILS = "DELETE_MANAGE_ORDER_DETAILS";

//// Shop-wise Payment Status Report
export const GET_Shop_wise_Payment_Status_Report =
  "GET_Shop-wise Payment Status Report";

//// Deliveryman wise collection report
export const GET_DLM_COLLECTION_REPORT = "GET_DLM_COLLECTION_REPORT";

//// Order Shop Details
export const GET_ORDER_SHOP_DETAILS = "GET_ORDER_SHOP_DETAILS";
export const ADD_ORDER_SHOP_DETAILS = "ADD_ORDER_SHOP_DETAILS";
export const GET_SINGLE_ORDER_SHOP_DETAILS = "GET_SINGLE_ORDER_SHOP_DETAILS";

//// Agents
export const ADD_AGENTS_DETAILS = "ADD_AGENTS_DETAILS";
export const GET_AGENTS_LIST = "GET_AGENTS_LIST";
export const DELETE_AGENT = "DELETE_AGENT";
export const SELECTED_AGENT_DETAILS = "SELECTED_AGENT_DETAILS";
export const UPDATE_AGENT_DETAILS = "UPDATE_AGENT_DETAILS";
export const GET_AGENT_SHOPS = "GET_AGENT_SHOPS";
export const TAG_AGENT_SHOP = "TAG_AGENT_SHOP";

//// AREA
export const ADD_AREA = "ADD_AREA";
export const DELETE_AREA = "DELETE_AREA";
export const RESET_AREAS = "RESET_AREAS";
//// CITY
export const ADD_CITY = "ADD_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";
export const RESET_CITY = "RESET_CITY";

//// AREA
export const GET_MFI_WALLET_LIMIT = "GET_MFI_WALLET_LIMIT";
export const ADD_MFI_WALLET_LIMIT = "ADD_MFI_WALLET_LIMIT";
export const DELETE_MFI_WALLET_LIMIT = "DELETE_MFI_WALLET_LIMIT";
export const RESET_MFI_WALLET_LIMIT = "RESET_MFI_WALLET_LIMIT";

//// GET_BUSINESS_ENTITY_TYPE_DDL
export const ADD_BUSINESS_ENTITY_TYPE = "ADD_BUSINESS_ENTITY_TYPE";
export const DELETE_BUSINESS_ENTITY_TYPE = "DELETE_BUSINESS_ENTITY_TYPE";

/// Banks
export const DELETE_BANK = "DELETE_BANK";

/// Pages
export const GET_PAGES = "GET_PAGES";
export const ADD_PAGE = "ADD_PAGE";
export const DELETE_PAGE = "DELETE_PAGE";

/// PageActions
export const GET_PAGE_ACTIONS = "GET_PAGE_ACTIONS";
export const ADD_PAGE_ACTION = "ADD_PAGE_ACTION";
export const DELETE_PAGE_ACTION = "DELETE_PAGE_ACTION";

/// Versions
export const GET_VERSIONS = "GET_VERSIONS";
export const ADD_VERSION = "ADD_VERSION";
export const DELETE_VERSION = "DELETE_VERSION";
export const UPDATE_VERSION = "UPDATE_VERSION";
/// UserRoles
export const GET_USER_ROLES_LIST = "GET_USER_ROLES_LIST";
export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const UPDATE_USER_ROLE_ = "UPDATE_USER_ROLE_";
export const DELETE_USER_ROLE = "DELETE_USER_ROLE";

/// UserRolePermissions
export const GET_USER_ROLE_PERMISSIONS = "GET_USER_ROLE_PERMISSIONS";
export const ADD_USER_ROLE_PERMISSIONS = "ADD_USER_ROLE_PERMISSIONS";
export const DELETE_USER_ROLE_PERMISSIONS = "DELETE_USER_ROLE_PERMISSIONS";

/// UserActivity
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";

/// DayWiseDailyTransactionsSummary
export const GET_DAY_WISE_DAILY_TRANSACTION_SUMMARY =
  "GET_DAY_WISE_DAILY_TRANSACTION_SUMMARY";
export const RESET_DAY_WISE_DAILY_TRANSACTION_SUMMARY =
  "RESET_DAY_WISE_DAILY_TRANSACTION_SUMMARY";

/// MonthlyDailyTransactionsSummary
export const GET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY =
  "GET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY";
export const RESET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY =
  "RESET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY";

/// CurrentShopwiseTransactionsDetails
export const GET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS =
  "GET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS";
export const RESET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS =
  "RESET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS";

/// CurrentDigitalCollectionsReport
export const GET_CURRENT_DIGITAL_COLLECTIONS_REPORT =
  "GET_CURRENT_DIGITAL_COLLECTIONS_REPORT";
export const RESET_CURRENT_DIGITAL_COLLECTIONS_REPORT =
  "RESET_CURRENT_DIGITAL_COLLECTIONS_REPORT";

/// MonthlyDigitalCollectionsReport
export const GET_MONTHLY_DIGITAL_COLLECTIONS_REPORT =
  "GET_MONTHLY_DIGITAL_COLLECTIONS_REPORT";
export const RESET_MONTHLY_DIGITAL_COLLECTIONS_REPORT =
  "RESET_MONTHLY_DIGITAL_COLLECTIONS_REPORT";

/// RsoDetailsReport
export const GET_RSO_DETAILS_REPORT = "GET_RSO_DETAILS_REPORT";
export const RESET_RSO_DETAILS_REPORT = "RESET_RSO_DETAILS_REPORT";

/// RsoPerformanceReport
export const GET_RSO_PERFORMANCE_REPORT = "GET_RSO_PERFORMANCE_REPORT";
export const RESET_RSO_PERFORMANCE_REPORT = "RESET_RSO_PERFORMANCE_REPORT";

//// sectors List
export const GET_SECTORS = "GET_SECTORS";

/// Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

//// Chat
export const SET_SOCKET = "SET_SOCKET";
export const SAVE_SOCKET_ID = "SAVE_SOCKET_ID";

export const GET_CHAT_LIST = "GET_CHAT_LIST";
export const GET_CHAT_BY_ID = "GET_CHAT_BY_ID";
export const GET_CHAT_SK_DETAILS = "GET_CHAT_SK_DETAILS";

export const LOAD_CHAT_MESSAGES = "LOAD_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const MESSAGES_READ = "MESSAGES_READ";
export const NOTIFICATION_VISITED = "NOTIFICATION_VISITED";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";

//Daily Collections Report
export const GET_DAILY_COLLECTIONS_REPORT = "GET_DAILY_COLLECTIONS_REPORT";

///VLP
export const GET_VLP_EVENTS_DDL = "GET_VLP_EVENTS_DDL";
export const GET_VLP_PAYER_DDL = "GET_VLP_PAYER_DDL";
export const GET_VLP_SHOP_CATEGORY_DDL = "GET_VLP_SHOP_CATEGORY_DDL";

export const ADD_VLP_SCHEME = "ADD_VLP_SCHEME";
export const GET_VLP_SCHEMES = "GET_VLP_SCHEMES";
export const UPDATE_VLP_SCHEME = "UPDATE_VLP_SCHEME";
export const DELETE_VLP_SCHEME = "DELETE_VLP_SCHEME";

export const GET_VLP_COIN_REPORT = "GET_VLP_COIN_REPORT";
export const GET_DIST_COINS_DISBURSEMENT_REPORT =
  "GET_DIST_COINS_DISBURSEMENT_REPORT";
export const GET_DELIVERYMAN_COINS_PAYMENT_REPORT =
  "GET_DELIVERYMAN_COINS_PAYMENT_REPORT";

///APP PULSE
export const GET_SHOPWISE_PULSE_SUMMARY_REPORT =
  "GET_SHOPWISE_PULSE_SUMMARY_REPORT";
export const GET_DATEWISE_SHOPS_SUMMARY_REPORT =
  "GET_DATEWISE_SHOPS_SUMMARY_REPORT";

//Till Number Details Report
export const GET_TILL_NUMBER_DETAILS_LIST = "GET_TILL_NUMBER_DETAILS_LIST";
export const GET_DISTRIBUTOR_COIN_PAYMENT_REPORT =
  "GET_DISTRIBUTOR_COIN_PAYMENT_REPORT";

//Loan Payments
export const GET_LOAN_DISBURSEMENT_REPORT = "GET_LOAN_DISBURSEMENT_REPORT";

//Finja Shop Loans
export const GET_FINJA_LOAN_REPORT = "GET_FINJA_LOAN_REPORT";

//Product Config
export const GET_DISTRIBUTOR_PRODUCT_CONFIG = "GET_DISTRIBUTOR_PRODUCT_CONFIG";

//VLConnect
export const GET_COMMUNICATION_LISTING = "GET_COMMUNICATION_LISTING";

//VLConnectConversation
export const GET_CONVERSATION = "GET_CONVERSATION";
export const RESET_CONVERSATION = "RESET_CONVERSATION";
export const SET_CHAT_DATA = "SET_CHAT_DATA";

//M2D Reports
export const GET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT =
  "GET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT";
export const RESET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT =
  "RESET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT";
export const GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT =
  "GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT";
export const RESET_GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT =
  "RESET_GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT";
export const GET_MONTHWISE_M2D_REPORT = "GET_MONTHWISE_M2D_REPORT";

//Advertisements
export const GET_ADVERTISMENTS = "GET_ADVERTISMENTS";
//Map Reports
export const GET_LOCALITY = "GET_LOCALITY";
export const GET_SUBLOCALITY = "GET_SUBLOCALITY";
export const GET_VIZSHOP_CATEGORIES = "GET_VIZSHOP_CATEGORIES";
export const GET_SECTORS_BASED_MAP = "GET_SECTORS_BASED_MAP";
export const RESET_ADVERTISMENTS = "RESET_ADVERTISMENTS";

//Reports - Shops Detail Reports
export const GET_SHOPS_DETAIL_REPORT = "GET_SHOPS_DETAIL_REPORT";

//SCR Reports
export const GET_SCR_DETAIL_REPORT = "GET_SCR_DETAIL_REPORT";
export const GET_SCR_COMPLETE_DETAIL = "GET_SCR_COMPLETE_DETAIL";
export const GET_SCR_REPORT = "GET_SCR_REPORT";
export const GET_SCR_SUMMARY_REPORT = "GET_SCR_SUMMARY_REPORT";
