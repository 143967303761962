import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from 'react'
import Moment from 'react-moment'

const SCRSummaryReportPrint = ({ dataModel, innerRef }) => {
    const hrStyle = {
        'margin-top': -5,
        border: 'none',
        height: '1px',
    }

    const textOverflow = {
        'overflow': 'hidden',
        'display': 'inline-block',
    }

    const [totalVizLinkCollectionSum, setTotalVizLinkCollectionSum] = useState(0);
    const [totalOutSideCollectionSum, settoTotalOutSideCollectionSum] = useState(0);
    const [totalBankCollection, setTotalBankCollection] = useState(0);

    const [currentEntity, setCurrentEntity] = useState({
        totalVizLinkCollection: [],
        totalOutSideCollection: [],
        totalBankCollection: [],
    });

    const ArraySum = (array, setter) => {
        if (array && array.length > 0) {
            let totalSum = 0;
            array.map((data) => {
                totalSum = totalSum + data.amount;
            })
            setter(totalSum);
        }
    }

    useEffect(() => {
        if (dataModel) {
            currentEntity.totalVizLinkCollection = dataModel.totalVizLinkCollection;
            currentEntity.totalOutSideCollection = dataModel.totalOutSideCollection;
            currentEntity.totalBankCollection = dataModel.totalBankCollection;

            ArraySum(currentEntity.totalVizLinkCollection, setTotalVizLinkCollectionSum);
            ArraySum(currentEntity.totalOutSideCollection, settoTotalOutSideCollectionSum);
            ArraySum(currentEntity.totalBankCollection, setTotalBankCollection);
        }
    }, [dataModel])


    const renderTransactionRow = (data) => {
        if (data) {
            return (
                <Row style={{ 'margin-top': -10 }}>
                    <Col sm={6} md={6}>
                        <label className="col-form-label pl-4">Total Collection Via {data.type}</label>
                    </Col>
                    <Col sm={2} md={2} className='text-center'>
                        {data.editedAmount}
                    </Col>
                    <Col sm={2} md={2} className='text-center'>
                        {data.amount}
                    </Col>
                </Row>
            )
        }
    }

    const renderSummaryColumn = (name, value) => {
        return (
            <div className="row align-items-center">
                <Col sm={7} md={7}>
                    <label className="col-form-label">{name}:</label>
                </Col>
                <Col sm={5} md={5}>
                    <span style={textOverflow}>{value ? value : ''}</span>
                </Col>
            </div>
        )
    }

    const renderCollectionHeading = (name, value) => {
        return (
            <Row>
                <Col sm={10} md={10}>
                    <label className="col-form-label"><b>{name}</b></label>
                </Col>
                <Col sm={2} md={2} className='text-center'>
                    <b>{value}</b>
                </Col>
            </Row>
        )
    }

    const renderSingleColumn = (name, editedValue, value) => {
        return (
            <>
                <hr style={hrStyle} />
                <Row style={{ 'margin-top': -10 }}>
                    <Col sm={6} md={6}>
                        <label className="col-form-label"><b>{name}</b></label>
                    </Col>
                    <Col sm={2} md={2} className='text-center' style={{ 'margin-top': 5 }}>
                        {editedValue ? editedValue : 0}
                    </Col>
                    <Col sm={2} md={2} className='text-center' style={{ 'margin-top': 5 }}>
                        {value ? value : 0}
                    </Col>
                    <Col sm={2} md={2} className='text-center' style={{ 'margin-top': 5 }}>
                        <b>{value ? value : 0}</b>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <div className='card mt-3'
            ref={innerRef}
        >
            <div className='card-body'>
                {!dataModel ? null : (<>
                    {/* ============ || Main Summary || ============*/}
                    <section>
                        <Row>
                            <Col>
                                {renderSummaryColumn("Distributor", dataModel.distributor)}
                            </Col>
                            <Col>
                                {renderSummaryColumn("Distribution Center", dataModel.distCenter)}
                            </Col>
                            <Col>
                                {renderSummaryColumn("Date", <Moment format='DD/MM/YYYY'>{dataModel.date ? dataModel.date : new Date()}</Moment>)}
                            </Col>
                        </Row>

                        <Row style={{ 'margin-top': -15 }}>
                            <Col>
                                {renderSummaryColumn("DLM User ID", dataModel.dlmUserName)}
                            </Col>
                            <Col>
                                {renderSummaryColumn("DLM Name", dataModel.dlmName)}
                            </Col>
                            <Col>
                                {renderSummaryColumn("DLM Till ID", dataModel.dlmTillId)}
                            </Col>
                        </Row>

                        <Row style={{ 'margin-top': -15 }}>
                            <Col>
                                {renderSummaryColumn("Total Invoices in Vizlink", dataModel.totalVizInvoices)}
                            </Col>
                            <Col>
                                {renderSummaryColumn("Total Invoices closed in Vizlink", dataModel.totalClosedVizInvoices)}
                            </Col>
                            <Col>
                                {renderSummaryColumn("Total Invoices Amount", dataModel.totalInvoicesAmount)}
                            </Col>
                        </Row>

                        <Row style={{ 'margin-top': -15 }}>
                            <Col sm={4} md={4}>
                                {renderSummaryColumn("Total COllected Amount", dataModel.totalCollectedAmount)}
                            </Col>
                        </Row>

                        <Row>
                            <div className='bg-gray'>
                                <Row>
                                    <Col sm={6} md={6}>
                                        <label className="col-form-label">Description</label>
                                    </Col>
                                    <Col sm={2} md={2} className='text-center'>
                                        <label className="col-form-label">Mode wise Breakup</label>
                                    </Col>
                                    <Col sm={2} md={2} className='text-center'>
                                        <label className="col-form-label">Mode wise Breakup</label>
                                    </Col>
                                    <Col sm={2} md={2} className='text-center'>
                                        <label className="col-form-label">Amount</label>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </section>

                    {/* ============ || Form Data || ============*/}
                    <section>
                        <Row className='mt-2'>
                            {renderCollectionHeading("Total Collection in Vizlink (M2D)", totalVizLinkCollectionSum)}
                            {currentEntity.totalVizLinkCollection ? currentEntity.totalVizLinkCollection.map((data, index) => {
                                return <div key={index}>
                                    {renderTransactionRow(data)}
                                </div>
                            }) : null}

                            <hr style={hrStyle} />
                            {renderCollectionHeading("Total Outside Vizlink", totalOutSideCollectionSum)}
                            {currentEntity.totalOutSideCollection ? currentEntity.totalOutSideCollection.map((data, index) => {
                                return <div key={index}>
                                    {renderTransactionRow(data)}
                                </div>
                            }) : null}

                            <hr style={hrStyle} />
                            {renderCollectionHeading("Total Collection in Bank", totalBankCollection)}
                            {currentEntity.totalBankCollection ? currentEntity.totalBankCollection.map((data, index) => {
                                return <div key={index}>
                                    {renderTransactionRow(data)}
                                </div>
                            }) : null}

                            {renderSingleColumn("Total Cash Collected", dataModel.totalCashCollected_Edited, dataModel.totalCashCollected)}
                            {renderSingleColumn("Total Credit Disbursed", dataModel.totalCreditDisbursed_Edited, dataModel.totalCreditDisbursed)}
                            {renderSingleColumn("Total RTG", dataModel.totalRTG_Edited, dataModel.totalRTG)}
                            {renderSingleColumn("Total Market Return", dataModel.totalMarketReturn_Edited, dataModel.totalMarketReturn)}
                            {renderSingleColumn("Total Additional Sale", dataModel.totalAdditionalSale_Edited, dataModel.totalAdditionalSale)}

                            <hr style={hrStyle} />
                            <Row style={{ 'margin-top': -10 }}>
                                <Col sm={10} md={10}>
                                    <label className="col-form-label"><b>Outstanding Amount</b></label>
                                </Col>
                                <Col sm={2} md={2} className='text-center'>
                                    <b>{dataModel ? dataModel.outstandingAmount : 0}</b>
                                </Col>
                            </Row>

                            <hr style={hrStyle} />
                            <Row style={{ 'margin-top': -10 }}>
                                <Col md={12}>
                                    <label className="col-form-label"><b>Total Receivable from Deliveryman</b></label>
                                </Col>
                            </Row>
                            <Row style={{ 'margin-top': -15 }}>
                                <Col sm={8} md={8}>
                                    <label className="col-form-label pl-4">Cash</label>
                                </Col>
                                <Col sm={2} md={2} className='text-center'>
                                    <b>{dataModel ? dataModel.totalReciveableFromDeliveryman ? dataModel.totalReciveableFromDeliveryman.cash : 0 : 0}</b>
                                </Col>
                            </Row>
                            <Row style={{ 'margin-top': -15 }}>
                                <Col sm={8} md={8}>
                                    <label className="col-form-label pl-4">Cheque - (Total No. of Cheques = {dataModel ? dataModel.totalReciveableFromDeliveryman ? dataModel.totalReciveableFromDeliveryman.totalNoOfChecque : 0 : 0})</label>
                                </Col>
                                <Col sm={2} md={2} className='text-center'>
                                    <b>{dataModel ? dataModel.totalReciveableFromDeliveryman ? dataModel.totalReciveableFromDeliveryman.cheque : 0 : 0}</b>
                                </Col>
                            </Row>

                            <hr style={hrStyle} />
                            <Row style={{ 'margin-top': -10 }}>
                                <Col sm={10} md={10}>
                                    <label className="col-form-label"><b>Total Cash Deposit in Bank</b></label>
                                </Col>
                                <Col sm={2} md={2} className='text-center'>
                                    <b> {dataModel ? dataModel.totalCashInBankDeposit : 0}</b>
                                </Col>
                            </Row>
                        </Row>
                    </section>
                </>)}
            </div>
        </div >
    )
};

export default SCRSummaryReportPrint;
