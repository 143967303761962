import axios from "axios";
import storage from "./Storage";

export default axios.create({
   //baseURL: 'https://localhost:5001/',
  //baseURL: "https://xgwk38bp-5001.inc1.devtunnels.ms/",
  // baseURL: "https://stagingapi.vizlinktech.com/",
  // baseURL: 'https://api.vizlinktech.com/',
  // baseURL: 'https://migrationapi.vizlinktech.com/',
  // baseURL: 'stagingapi.vizlinktech.com/'
   baseURL: "https://devapi.vizlinktech.com/",
  headers: {
    Authorization: `Bearer ${storage.get("_appToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
