import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import vizLogo from '../../assets/Images/Vizlink_logo.png';

export default function CreditBookTermsAndConditions() {
    return (
        <div>
            <div
                className='login-page'
                style=
                {{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className='container-xxl'>
                    <div class="card mt-4" style={{ height: '90vh' }}>
                        <div className='card-header text-center'>
                            <Link
                                className='brand-link clearfix'
                                to={'/login'}
                            >
                                <Image
                                    src={vizLogo}
                                    alt={'VizLink App Logo'}
                                />
                            </Link>
                        </div>

                        {/* <div class="card-header text-center">
                            Sigma loan
                        </div> */}
                        <div class="card-body overflow-auto" >
                            <h2 className=' text-center'>Terms & Conditions</h2>
                            <section>
                                <b>Karobar Barhana Hai? Munafa Kamana Hai?</b>
                                <p>Shariah-compliant Wakalah bil Ujrah Stock Financing ab aap ki pohanch mein hai.
                                    Abhi click karein aur fouri hasil karein!</p>
                            </section>
                            <section>
                                <b>Terms & Conditions:</b>
                                <p></p>
                                <ul>
                                    <li><p><b>Transaction Fee: </b> 0.6% per financing.</p></li>
                                    <li><p><b>Service Fee: </b> 48% APR (0.1315% daily). Service fee pro rata basis pe calculate hoti hai. Example: Rs. 10,000 ki financing 10 din ke liye, sirf Rs. 131.50 fees deni hogi.</p></li>
                                    <li><p><b>Financing Limit: </b> Sirf ek financing ek waqt par allowed hai. Maximum 30 din ki financing facility hai.</p></li>
                                    <li><p><b>Late Fee: </b> 3 din kay grace period ke baad, agar financing 30 din mein repay nahi hui, tou 0.5% penalty principal amount par lagegi. </p></li>
                                </ul>
                            </section>
                            <section>
                                <b>Note :</b>
                                <p> Retailers ki doosre banks ya institutions ke saath koi outstanding financing nahi honi chahiye.</p>
                                <p></p>
                                <p>Mazeed maalumaat ke liye, call karain: 03069444111</p>
                            </section>
                        </div>
                        <div class="card-footer text-muted">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
