import axios from "../../../../apis/Api";
import history, { logout } from "../../../../history";
import { GET_SCR_COMPLETE_DETAIL } from "../../../../Types";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const getScrCompleteDetailReport = (request) => async (dispatch) => {
  dispatch(showLoading());

  await axios
    .post(`api/SCRReports/GetSCRCompleteDetail`, request)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_SCR_COMPLETE_DETAIL,
        payload: data.response,
      });
      dispatch(hideLoading());
    })
    .catch(function (error) {
      dispatch(hideLoading());
      if (error.response) {
        if (error.response.status === 401) {
          logout();
          history.push("/login");
          history.go("/login");
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          );
        else {
          toast.error("Error while processing the request");
        }
      }
    });
};
