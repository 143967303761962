import {
  GET_DISTRIBUTOR_DDL,
  GET_JOBS_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_RSOS_DDL,
  RESET_DDL,
  GET_BANKS,
  GET_CITIES,
  GET_BUSINESS_ENTITIES,
  GET_USER_ROLES,
  GET_RSO_USERS,
  GET_AREAS,
  DELETE_AREA,
  RESET_AREAS,
  GET_MFI_DDL,
  GET_MFI_WALLET_LIMIT,
  RESET_MFI_WALLET_LIMIT,
  DELETE_MFI_WALLET_LIMIT,
  GET_BUSINESS_ENTITY_TYPE_DDL,
  DELETE_BUSINESS_ENTITY_TYPE,
  DELETE_BANK,
  GET_ALL_DISTRIBUTION_CENTERS_DDL,
  GET_VLP_EVENTS_DDL,
  GET_VLP_PAYER_DDL,
  GET_VLP_SHOP_CATEGORY_DDL,
  GET_VLC_TICKET_CATEGORIES_DDL,
  GET_VIZ_IDS_DDL,
  GET_DELIVERYMAN_TILL_DDL,
  GET_USER_ROLES_LIST,
  GET_LOCALITY,
  GET_SUBLOCALITY,
  GET_VIZSHOP_CATEGORIES,
  GET_PAYMENT_TYPES_DDL,
} from "../Types";

const initialState = {
  distributorList: [],
  selectedDistCentersList: [],
  selectedAddJobDistCentersList: [],
  allDistributorList: [],
  filteredDistributorList: [],
  rsosList: [],
  banks: [],
  cities: [],
  areas: [],
  VLConnectTicketCategories: [],

  distCenters: [],
  businessEntities: [],
  userRoles: [],
  rsoUsers: [],
  mfiList: [],
  paymentTypeList: [],
  mfiWalletList: [],
  businessEntityTypesList: [],
  vlpEvents: [],
  vlpPayers: [],
  vlpShopCategories: [],
  deliverymanTills: [],
  locality: [],
  subLocality: [],
  vizShopCategories: [],
};

const ddlListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISTRIBUTOR_DDL:
      return { ...state, allDistributorList: action.payload };
    case GET_JOBS_DISTRIBUTOR_DDL:
      return { ...state, filteredDistributorList: action.payload };
    case GET_DISTRIBUTIONCENTER_DDL:
      return { ...state, selectedDistCentersList: action.payload };
    case GET_ADDJOB_DISTRIBUTIONCENTER_DDL:
      return { ...state, selectedAddJobDistCentersList: action.payload };
    case GET_RSOS_DDL:
      return { ...state, rsosList: action.payload };
    case GET_USER_ROLES:
      return { ...state, userRoles: action.payload };
    case GET_USER_ROLES_LIST:
      return { ...state, UserRolesList: action.payload };
    case GET_ALL_DISTRIBUTION_CENTERS_DDL:
      return { ...state, distCenters: action.payload };
    case GET_BANKS:
      return {
        ...state,
        banks: action.payload.map(function (item) {
          return {
            value: item.bankName,
            label: item.bankName,
            id: item.id,
          };
        }),
      };
    case DELETE_BANK:
      return {
        ...state,
        banks: state.banks.filter((x) => x.id !== action.payload),
      };
    case GET_CITIES:
      return {
        ...state,
        cities: action.payload.map(function (item) {
          return {
            value: item.name,
            label: item.name,
            polygon: item.cityPoligon,
            code: item.cityCode,
            id: item.id,
          };
        }),
      };
    case GET_AREAS:
      return {
        ...state,
        areas: action.payload.map(function (item) {
          return {
            value: item.name,
            label: item.name,
            id: item.id,
          };
        }),
      };
    case DELETE_AREA:
      return {
        ...state,
        areas: state.areas.filter((x) => x.id !== action.payload),
      };
    case RESET_AREAS:
      return {
        ...state,
        areas: [],
      };
    case GET_BUSINESS_ENTITIES:
      return {
        ...state,
        businessEntities: action.payload.map(function (item) {
          return {
            value: item.typeName,
            label: item.typeName,
          };
        }),
      };
    case GET_MFI_DDL:
      return {
        ...state,
        mfiList: action.payload.map(function (item) {
          return {
            value: item.mfiName,
            label: item.mfiName,
            id: item.id,
          };
        }),
      };
    case GET_PAYMENT_TYPES_DDL:
      return {
        ...state,
        paymentTypeList: action.payload.map(function (item) {
          return {
            value: item.mfiName,
            label: item.mfiName,
            id: item.id,
          };
        }),
      };
    case GET_MFI_WALLET_LIMIT:
      return {
        ...state,
        mfiWalletList: action.payload.map(function (item) {
          return {
            value: item.name,
            label: item.name,
            id: item.id,
          };
        }),
      };
    case DELETE_MFI_WALLET_LIMIT:
      return {
        ...state,
        mfiWalletList: state.mfiWalletList.filter(
          (x) => x.id !== action.payload
        ),
      };
    case RESET_MFI_WALLET_LIMIT:
      return {
        ...state,
        mfiWalletList: [],
      };
    case GET_BUSINESS_ENTITY_TYPE_DDL:
      return {
        ...state,
        businessEntityTypesList: action.payload.map(function (item) {
          return {
            value: item.typeName,
            label: item.typeName,
            id: item.id,
          };
        }),
      };
    case DELETE_BUSINESS_ENTITY_TYPE:
      return {
        ...state,
        businessEntityTypesList: state.businessEntityTypesList.filter(
          (x) => x.id !== action.payload
        ),
      };
    case GET_RSO_USERS:
      return { ...state, rsoUsers: action.payload };
    case GET_VLP_EVENTS_DDL:
      return {
        ...state,
        vlpEvents: action.payload.map(function (item) {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      };
    case GET_VLP_PAYER_DDL:
      return {
        ...state,
        vlpPayers: action.payload.map(function (item) {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      };
    case GET_VLP_SHOP_CATEGORY_DDL:
      return {
        ...state,
        vlpShopCategories: action.payload.map(function (item) {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      };
    case GET_VLP_PAYER_DDL:
      return { ...state, vlpPayers: action.payload };
    case GET_VLP_SHOP_CATEGORY_DDL:
      return { ...state, vlpShopCategories: action.payload };
    case RESET_DDL:
      if (action.payload == "FilterDistCenters")
        return { ...state, selectedDistCentersList: [] };
      if (action.payload == "FilterRsos") return { ...state, rsosList: [] };
      if (action.payload == "FilterCity") return { ...state, citiesList: [] };
    case GET_VLC_TICKET_CATEGORIES_DDL:
      return { ...state, VLCTicketCategories: action.payload };
    case GET_VIZ_IDS_DDL:
      return { ...state, VizShopIds: action.payload };
    case GET_DELIVERYMAN_TILL_DDL: {
      return { ...state, deliverymanTills: action.payload };
    }
    case GET_LOCALITY:
      return { ...state, locality: action.payload };
    case GET_SUBLOCALITY:
      return { ...state, subLocality: action.payload };
    case GET_VIZSHOP_CATEGORIES:
      return { ...state, vizShopCategories: action.payload };
    default:
      return { ...state };
  }
};
export default ddlListReducer;
