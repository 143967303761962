import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Moment from "react-moment";

const SCRInvoiceImagePrint = ({ dataModel, innerRef }) => {
  return (
    <>
      <Container
        ref={innerRef}
        style={{ paddingTop: "50px", fontSize: "12px" }}
      >
        <div>
          <Row>
            <Col>
              <h5 style={{ textAlign: "center" }}>Invoice Image</h5>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>Dist Shop Code/Viz ID:</Col>
            <Col>
              {dataModel?.distShopCode} / {dataModel?.vizShopCode}
            </Col>
            <Col>Shop Name:</Col>
            <Col>{dataModel?.shopName}</Col>
          </Row>
          <Row>
            <Col>Invoice #:</Col>
            <Col>{dataModel?.invoiceNumber}</Col>
            <Col>Due Date:</Col>
            <Col>
              <Moment format="DD/MM/YYYY">
                {dataModel?.dueDate ? dataModel?.dueDate : new Date()}
              </Moment>
            </Col>
          </Row>
          <Row>
            <Col>Amount:</Col>
            <Col>Rs.{dataModel?.amount}</Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col>
              <Image
                style={{ height: "100%", width: "100%" }}
                src={dataModel?.filePath}
              ></Image>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default SCRInvoiceImagePrint;
