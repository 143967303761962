import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Image } from "react-bootstrap";

const SCRIBFTImagePrint = ({ dataModel, innerRef }) => {
  return (
    <>
      <Container
        ref={innerRef}
        style={{ paddingTop: "50px", fontSize: "12px" }}
      >
        <div>
          <Row>
            <Col>
              <h5 style={{ textAlign: "center" }}>Cheque Image</h5>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>Dist Shop Code/Viz ID:</Col>
            <Col>
              {dataModel?.distShopCode} / {dataModel?.vizShopCode}
            </Col>
            <Col>Shop Name:</Col>
            <Col>{dataModel?.shopName}</Col>
          </Row>
          <Row>
            <Col>Invoice #:</Col>
            <Col>{dataModel?.invoiceNumber}</Col>
            <Col>Dist Bank:</Col>
            <Col>{dataModel?.distBankId}</Col>
          </Row>
          <Row>
            <Col>Amount:</Col>
            <Col>{dataModel?.amount}</Col>
            <Col>Payer Name:</Col>
            <Col>{dataModel?.payerName}</Col>
          </Row>
          <Row>
            <Col>Payer Bank:</Col>
            <Col>{dataModel?.payerBankId}</Col>
            <Col>TID #:</Col>
            <Col>{dataModel?.tid}</Col>
          </Row>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col>
              <Image
                style={{ height: "100%", width: "100%" }}
                src={dataModel?.filePath}
              ></Image>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default SCRIBFTImagePrint;
