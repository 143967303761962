import React, { useRef, useEffect, useState } from "react";
import { VzPageContainer } from "../../../layouts/VzPageContainer";
import { connect } from "react-redux";
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from "react-bootstrap";
import ServerSideDataTable from "../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable";
import CustomDataTable from "../../../layouts/DataTables/CustomDataTable";
import axios from "../../../apis/Api";
import { getDropdownList } from "../../Shared/dropdownListActions";
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from "../../../Types";
import FormikControl from "../../../layouts/Input/FormikControl";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import storage from "../../../apis/Storage";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../utilities/FUNCTIONS";
import Moment from "react-moment";
export const showLoader = () => async (dispatch) => {
  dispatch(showLoading());
};

export const hideLoader = () => async (dispatch) => {
  dispatch(hideLoading());
};

const InvoiceReopeningListing = (props) => {
  const userRights = JSON.parse(storage.get("_userRights"));
  const [invoiceList, setInvoiceList] = useState([]);
  const [deliveryManFilterList, setDeliveryManFilterList] = useState([]);

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const [showInvoices, setShowInvoices] = useState(false);
  const [targetInvoices, setTargetInvoices] = useState(null);
  const refInvoices = useRef(null);

  const [distributorList, setDistributorList] = useState([]);
  const [distCenterList, setdistCenterList] = useState([]);
  const [deliveryManNameList, setDeliveryManNameList] = useState([]);

  const [filter, setfilter] = useState({
    distributor: null,
    distCenter: null,
    deliveryManName: null,
    deliveryManTillNo: null,
    fromDate: new Date(),
    toDate: new Date(),
    pageNo: 0,
    searchText: null,
  });

  const history = useHistory();

  const openFilterModal = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  function formRequestObject() {
    let requestData = {
      distributor: filter.distributor?.value,
      distCenter: filter.distCenter?.value,
      deliveryManTillNo: filter.deliveryManTillNo?.value,
      deliveryManName: filter.deliveryManName?.value,
      fromDate: filter.fromDate && formatDate(filter.fromDate),
      toDate: filter.fromDate && formatDate(filter.toDate),
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
    };

    return requestData;
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0);
    setfilter({ ...filter, searchText: text, currentPageNo: 0 });
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    };
    fetchReportData(request);
  };

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    getInvoiceList(finalRequest);
  }

  const getInvoiceList = (request) => {
    props.showLoader();
    axios
      .post(`api/InvoiceReOpening/GetReopenInvoicesListing`, request)
      .then(({ data }) => {
        props.hideLoader();
        setInvoiceList(data?.response?.data);
        setTotalRecords(data?.response?.totalRecords);
      })
      .catch(function (error) {
        props.hideLoader();
      });
  };

  const getData = (page) => {
    setCurrentPageNo(page);
    var request = { ...formRequestObject(), pageNo: page };
    fetchReportData(request);
  };

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 };
    fetchReportData(request);
    setShow(false);
  };

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0);
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize };
    fetchReportData(request);
  };

  const handleResetFilters = (resetForm) => {
    resetForm();
    setDeliveryManFilterList([]);
    setfilter({
      distributor: null,
      distCenter: null,
      deliveryManName: null,
      deliveryManTillNo: null,
      fromDate: new Date(),
      toDate: new Date(),
      pageNo: 0,
      searchText: null,
    });
  };

  // For Double Click
  const onRowClick = (e, rowData) => {
    debugger;
    let requestPrevious = {
      distCenter: filter.distCenter,
      deliveryManId: rowData?.dlManId,
      fromDate: rowData?.orderDeliveryDate,
      toDate: rowData?.orderDeliveryDate,
    };

    history.push("/InvoiceReopeningListingDetail", {
      requestPrevious,
    });

    <Link
      to={{
        pathname: "/InvoiceReopeningListingDetail",
        state: { requestPrevious },
      }}
    ></Link>;
  };

  const columns = [
    {
      Header: "Distribution Center",
      accessor: "distCenterName",
    },

    {
      Header: "Date",
      accessor: "orderDeliveryDate",
      Cell: (row) => (
        <div style={{ width: "75px" }}>
          <Moment format="DD/MM/YYYY">{row.value}</Moment>
        </div>
      ),
    },

    {
      Header: "Deliveryman Name",
      accessor: "dlmName",
    },

    {
      Header: "Total Invoice",
      accessor: "totalInvoices",
    },

    {
      Header: "Finalized Invoices",
      accessor: "finalizedInvoices",
    },

    {
      Header: "Unfinalized Invoices",
      accessor: "unfinalizedInvoices",
    },

    {
      Header: "Credit Invoices",
      accessor: "creditInvoices",
    },
  ];

  useEffect(() => {
    fetchReportData();
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    );
  }, []);

  useEffect(() => {
    if (userRights != null && userRights?.userType == "DistributorUser") {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      );
      if (distributorList?.length > 0) {
        setfilter({
          ...filter,
          distributor: {
            label: distributorList[0]?.label,
            value: distributorList[0]?.value,
          },
        });
      }
      setDistributorList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          };
        })
      );
    } else {
      setDistributorList(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          };
        })
      );
    }
  }, [props.allDistributorList]);

  useEffect(() => {
    if (userRights != null && userRights?.userType == "DistributorUser") {
      let distCenterList = props.selectedAddJobDistCentersList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      );
      setdistCenterList(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          };
        })
      );
    } else {
      setdistCenterList(
        props.selectedAddJobDistCentersList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          };
        })
      );
    }
  }, [props.selectedAddJobDistCentersList]);

  useEffect(() => {
    if (filter.distributor) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filter.distributor.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      );
    } else {
      filter.distCenter = null;
      filter.deliveryManName = null;
      filter.deliveryManTillNo = null;
    }
  }, [filter.distributor]);

  useEffect(() => {
    filter.deliveryManName = null;
    filter.deliveryManTillNo = null;
  }, [filter.distCenter]);

  const getCenterDeliveryUsers = (id) => {
    axios
      .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
      .then(({ data }) => {
        if (data?.response) {
          setDeliveryManNameList(
            data.response.map(function (item) {
              return {
                value: item.qrCode,
                label: item.fullname,
              };
            })
          );
        }
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (filter.distCenter) {
      getCenterDeliveryUsers(filter.distCenter.value);
    }
  }, [filter.distCenter]);

  return (
    <div>
      <VzPageContainer title="Daily Orders > Invoice Re-Opening">
        <Row>
          <Col md={{ span: 3 }} style={{ float: "left" }}>
            <h4>
              <b>Invoice Re-Opening</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10} style={{ float: "right" }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className="m-3"
                variant="outline-primary"
              >
                <i className="fa fa-filter" />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
                width="430px"
                style={{ width: "430px" }}
              >
                <Popover
                  id="popover-contained"
                  style={{ maxWidth: "360px", width: "360px" }}
                >
                  <Popover.Header as="h3">Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filter}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete="off">
                          <Row>
                            {userRights == null && (
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control="react-select"
                                  label="Distributor"
                                  name="distributor"
                                  options={distributorList}
                                  isClearable={true}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setfilter({
                                        ...filter,
                                        distributor: null,
                                      });
                                      return;
                                    }
                                    setfilter({
                                      ...filter,
                                      distributor: {
                                        label: e.label,
                                        value: e.value,
                                      },
                                    });
                                  }}
                                  value={filter.distributor}
                                />
                              </Col>
                            )}
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Distribution Center"
                                name="distributionCenter"
                                options={distCenterList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    distCenter: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.distCenter}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control="react-select"
                                label="Deliveryman Name"
                                name="deliverymanName"
                                options={deliveryManNameList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return;
                                  }
                                  setfilter({
                                    ...filter,
                                    deliveryManName: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  });
                                }}
                                value={filter.deliveryManName}
                              />
                            </Col>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  type="fromDate"
                                  control="date"
                                  label="From Date"
                                  name="fromDate"
                                  handleOnChange={(value) => {
                                    setfilter({ ...filter, fromDate: value });
                                  }}
                                  value={filter.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  control="date"
                                  type="toDate"
                                  label="To Date"
                                  name="toDate"
                                  handleOnChange={(value) => {
                                    setfilter({ ...filter, toDate: value });
                                  }}
                                  value={filter.toDate}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group">
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "left",
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false);
                                  setTarget(null);
                                }}
                                to="#"
                                className="active"
                                style={{
                                  padding: "0px",
                                  marginTop: "10px",
                                  float: "right",
                                }}
                              >
                                Cancel
                              </Link>
                              <div className="float-right">
                                <Button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{
                                    marginRight: "10px",
                                    float: "right",
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} ref={refInvoices}>
            <ServerSideDataTable
              data={invoiceList ?? []}
              columns={columns}
              getTrProps={(event, data) => onRowClick(event, data)}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
              searchLabel="Search Invoice :"
            />
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  };
};
export default connect(mapStateToProps, {
  getDropdownList,
  showLoader,
  hideLoader,
})(InvoiceReopeningListing);
